import gql from "graphql-tag";

import { ORGANISATION_BASKET_FIELDS, ORGANISATION_FIELDS } from "./shared";

export const CREATE_NEW_ORGANISATION_BASKET_MUTATION = gql`
  mutation CreateOrganisationBasketMutation($input: CreateBasketMutationInput!) {
    createBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ADD_PRODUCT_TO_ORGANISATION_BASKET_MUTATION = gql`
  mutation AddProductToOrganisationBasketMutation($input: AddProductToBasketMutationInput!) {
    addProductToBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const REMOVE_PRODUCT_FROM_ORGANISATION_BASKET_MUTATION = gql`
  mutation RemoveProductFromOrganisationBasketMutation(
    $input: RemoveProductFromBasketMutationInput!
  ) {
    removeProductFromBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ADD_SUPPLEMENT_TO_ORGANISATION_BASKET_MUTATION = gql`
  mutation AddSupplementToOrganisationBasketMutation($input: AddSupplementToBasketMutationInput!) {
    addSupplementToBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const REMOVE_SUPPLEMENT_FROM_ORGANISATION_BASKET_MUTATION = gql`
  mutation RemoveSupplementFromOrganisationBasketMutation(
    $input: RemoveSupplementFromBasketMutationInput!
  ) {
    removeSupplementFromBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const UPDATE_ORGANISATION_BASKET_DETAILS_MUTATION = gql`
  mutation UpdateOrganisationBasketDetailsMutation($input: UpdateBasketDetailsMutationInput!) {
    updateBasketDetailsMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const CREATE_PATIENT_MUTATION = gql`
  mutation CreatePatientMutation($input: CreatePatientMutationInput!) {
    createPatientMutation(input: $input) {
      patient {
        id
        platformUserProfile {
          id
          pk
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      errors {
        field
      }
    }
  }
`;

export const CREATE_SHIPPING_ADDRESS_MUTATION = gql`
  mutation CreateShippingAddressMutation($input: CreateShippingAddressMutationInput!) {
    createShippingAddressMutation(input: $input) {
      address {
        id
        name
        line1
        line2
        city
        postcode
        country {
          id
          name
          isoCode
        }
      }
      errors {
        field
      }
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS_MUTATION = gql`
  mutation UpdateShippingAddressMutation($input: UpdateShippingAddressMutationInput!) {
    updateShippingAddressMutation(input: $input) {
      address {
        id
        name
        line1
        line2
        city
        postcode
        country {
          id
          name
          isoCode
        }
      }
      errors {
        field
      }
    }
  }
`;

export const FINALISE_BASKET_MUTATION = gql`
  mutation FinaliseBasketMutation($input: FinaliseBasketMutationInput!) {
    finaliseBasketMutation(input: $input) {
      basket {
        ...OrganisationBasketFields
      }
      order {
        id
      }
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const UPDATE_ORGANISATION_DETAILS_MUTATION = gql`
  mutation UpdateOrganisationDetailsMutation($input: UpdateOrganisationDetailsMutationInput!) {
    updateOrganisationDetailsMutation(input: $input) {
      organisation {
        ...OrganisationFields
      }
      errors {
        field
      }
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const CREATE_PAYMENT_INTENT_MUTATION = gql`
  mutation CreatePaymentIntentMutation($input: CreatePaymentIntentMutationInput!) {
    createPaymentIntentMutation(input: $input) {
      paymentIntent {
        id
        url
      }
    }
  }
`;

export const CREATE_PAYMENT_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreatePaymentCheckoutSessionMutation(
    $input: CreatePaymentCheckoutSessionMutationInput!
  ) {
    createPaymentCheckoutSessionMutation(input: $input) {
      paymentCheckoutSession {
        id
        url
      }
    }
  }
`;

export const CREATE_PAYMENT_INVOICE_MUTATION = gql`
  mutation CreatePaymentInvoiceMutation($input: CreatePaymentInvoiceMutationInput!) {
    createPaymentInvoiceMutation(input: $input) {
      paymentInvoice {
        id
        url
      }
      order {
        id
      }
    }
  }
`;

export const CREATE_PATIENT_ORDER_FROM_BASKET_MUTATION = gql`
  mutation CreatePatientOrderFromBasketMutation(
    $input: CreatePatientOrderFromBasketMutationInput!
  ) {
    createPatientOrderFromBasketMutation(input: $input) {
      order {
        id
      }
    }
  }
`;

export const INVITE_YOUR_PATIENT_MUTATION = gql`
  mutation InviteYourPatientMutation($input: InviteYourPatientMutationInput!) {
    inviteYourPatientMutation(input: $input) {
      patient {
        id
        platformUserProfile {
          id
          pk
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;
