import { forwardRef } from "react";

import Box from "components/Box";
import { theme } from "core/theme";
import { ReactComponent as Tick } from "images/tick.svg";

import { Error } from "./InputWrapper";

const BaseCheckbox = forwardRef(
  ({ name, onChange, value, disabled, color, checked: checkedProp, uncheckedColor }, ref) => {
    /**
     * For checkbox group the value will be non boolean in which case you checkedProp,
     * otherwise for a single checkbox the value should be boolean.
     */

    const checked = value === false || value === true ? value : checkedProp;

    return (
      // TODO - id prop below is wrong for checkbox group
      // because all checkboxes inherit same name
      // sort this

      <Box position="relative" data-component-name="checkbox">
        <Box
          as="input"
          id={name}
          type="checkbox"
          onChange={onChange}
          value={value}
          disabled={disabled}
          opacity={0}
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          height={50}
          ref={ref}
          name={name}
          checked={checked}
        />
        <Box htmlFor={name} as="label" color={color} display="flex" alignItems="center">
          <Box
            bg={checked ? "green" : "transparent"}
            width={50}
            height={50}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderColor={checked ? "green" : uncheckedColor}
            borderWidth="2px"
            borderStyle="solid"
            borderRadius="5px"
            flexShrink={0}
          >
            <Tick fill={checked ? "white" : uncheckedColor} width={25} />
          </Box>
        </Box>
      </Box>
    );
  }
);

const Checkbox = forwardRef(({ label, error, uncheckedColor, ...props }, ref) => (
  <Box className="inputWrapper">
    <Box display="flex" alignItems="center" gap={10}>
      <BaseCheckbox
        {...props}
        uncheckedColor={error ? theme.colors.red : uncheckedColor}
        ref={ref}
      />
      <Box fontFamily="gilroyMedium" fontSize={14}>
        {label}
      </Box>
    </Box>
    {error && <Error error={error} mt={2} />}
  </Box>
));

Checkbox.defaultProps = {
  uncheckedColor: theme.colors.carbon
};

export default Checkbox;
