import gql from "graphql-tag";

// Queries

export const PLATFORM_USER_PROFILE_FIELDS = gql`
  fragment PlatformUserProfileFields on PlatformUserProfileType {
    id
    pk
    height
    weight
    dateOfBirth
    gender
    isComplete
    stripeCustomerId
    stripeCardPaymentMethodId
    stripeCardExpMonth
    stripeCardExpYear
    stripeCardLast4
    stripeCardBrand
    user {
      id
      firstName
      lastName
      email
    }
    goals {
      id
    }
    dietPreferences {
      id
    }
    marketingConsent
  }
`;

export const PLATFORM_USER_PROFILE_QUERY = gql`
  query PlatformUserProfileQuery {
    platformUserProfile {
      ...PlatformUserProfileFields
    }
  }
  ${PLATFORM_USER_PROFILE_FIELDS}
`;

export const PARTNER_USER_PROFILE_FIELDS = gql`
  fragment PartnerUserProfileFields on PartnerUserProfileType {
    id
    pk
    tier
    approved
    user {
      id
      firstName
      lastName
      hashId
    }
    discountSet {
      id
      code
      testPercentage
      commissionPercentage
    }
    jobRole
    phoneNumber
    description
    descriptionOther
    qualifications
    specialisms
    specialismsOther
    instagram
    tiktok
    youtube
    linkedin
    websiteBlog
  }
`;

export const PARTNER_USER_PROFILE_QUERY = gql`
  query PartnerUserProfileQuery {
    partnerUserProfile {
      ...PartnerUserProfileFields
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
`;

export const AFFILIATE_LINK_QUERY = gql`
  query AffiliateLinkQuery($id: String!) {
    affiliateLink(id: $id) {
      id
      resultsAccessLink {
        id
        granted
      }
      partnerUserProfile {
        id
        user {
          id
          identifier
        }
      }
    }
  }
`;

export const RESULTS_ACCESS_REQUEST_QUERY = gql`
  query ResultAccessRequestQuery($id: String!) {
    resultsAccessLink(id: $id) {
      id
      partnerUserProfile {
        id
        user {
          id
          identifier
        }
      }
    }
  }
`;

export const AFFILIATE_LINK_FIELDS = gql`
  fragment AffiliateLinkFields on AffiliateLinkType {
    id
    created
    orderTestItemsTotal
    orderSupplementItemsTotal
    orderTestItemsCommission
    orderSupplementItemsCommission
    partnerUserProfile {
      id
      tier
    }
    resultsAccessLink {
      id
      granted
    }
    platformUserEmail
    platformUserProfile {
      id
      user {
        id
        email
      }
    }
  }
`;
export const USER_PURCHASE_COMMISSION_FIELDS = gql`
  fragment UserPurchaseCommissionFields on CommissionType {
    orderDescription
    discount
    commission
    orderDate
    orderId
  }
`;

export const AFFILIATE_LINKS_QUERY = gql`
  query AffiliateLinksQuery {
    affiliateLinks {
      ...AffiliateLinkFields
    }
  }
  ${AFFILIATE_LINK_FIELDS}
`;

export const RESULTS_ACCESS_LINK_FIELDS = gql`
  fragment ResultsAccessLinkFields on ResultsAccessLinkType {
    id
    granted
    created
    userTests {
      id
    }
    platformUserProfile {
      id
      user {
        id
        email
        identifier
      }
    }
    partnerUserProfile {
      id
      user {
        id
        email
        identifier
      }
    }
    affiliateLink {
      id
      platformUserEmail
    }
    userTests {
      testProduct {
        id
        name
      }
      dataFiles {
        id
        name
        dataFileUrl
        testDataFile {
          id
          fileType {
            id
            name
            contentType
            extension
          }
        }
      }
      generatedPdfs {
        id
        pdfFile
        userTest {
          id
        }
      }
    }
  }
`;

export const PARTNER_LINKS_QUERY = gql`
  query PartnerLinksQuery {
    affiliateLinks {
      ...AffiliateLinkFields
    }
    partnerUserResultsAccessLinks {
      ...ResultsAccessLinkFields
    }
  }
  ${AFFILIATE_LINK_FIELDS}
  ${RESULTS_ACCESS_LINK_FIELDS}
`;

export const DISCOUNT_CODE_AND_LINK_COMMISSIONS_QUERY = gql`
  query DiscountCodeAndLinkCommissionsQuery {
    discountCodeAndLinkCommissions {
      ...UserPurchaseCommissionFields
    }
  }
  ${USER_PURCHASE_COMMISSION_FIELDS}
`;

export const PLATFORM_USER_RESULTS_ACCESS_REQUESTS_QUERY = gql`
  query PlatformUserResultAccessRequestsQuery {
    platformUserResultsAccessLinks {
      ...ResultsAccessLinkFields
    }
  }
  ${RESULTS_ACCESS_LINK_FIELDS}
`;

export const PAYOUT_FIELDS = gql`
  fragment PayoutFields on PayoutType {
    id
    created
    amount
    status
  }
`;

export const PAYOUTS_QUERY = gql`
  query PayoutsQuery {
    payouts {
      ...PayoutFields
    }
  }
  ${PAYOUT_FIELDS}
`;

// Mutations

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($input: SignupMutationInput!) {
    signupMutation(input: $input) {
      email
      errors {
        field
        messages
      }
    }
  }
`;

export const KIT_SIGNUP_MUTATION = gql`
  mutation KitSignupMutation($input: KitSignupMutationInput!) {
    kitSignupMutation(input: $input) {
      email
      errors {
        field
        messages
      }
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation ActivateAccountMutation($input: ActivateAccountMutationInput!) {
    activateAccountMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const LOG_IN_MUTATION = gql`
  mutation LogInMutation($input: LogInMutationInput!) {
    logInMutation(input: $input) {
      user {
        id
        email
        dateJoined
        fullName
        partnerUserProfile {
          ...PartnerUserProfileFields
        }
      }
      errors {
        field
        messages
      }
      csrfToken
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
`;

export const LOG_OUT_MUTATION = gql`
  mutation LogOutMutation {
    logOutMutation {
      result
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($input: ChangePasswordMutationInput!) {
    changePasswordMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const REQUEST_PASSWORD_RESET_MUTATION = gql`
  mutation RequestPasswordResetMutation($input: RequestPasswordResetMutationInput!) {
    requestPasswordResetMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmailMutation($input: ResendVerificationEmailMutationInput!) {
    resendVerificationEmailMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($input: ResetPasswordMutationInput!) {
    resetPasswordMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_ADMIN_INVITATION_MUTATION = gql`
  mutation AcceptAdminInvitationMutation($input: AcceptAdminInvitationMutationInput!) {
    acceptAdminInvitationMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_AFFILIATE_INVITATION_MUTATION = gql`
  mutation AcceptAffiliateInvitationMutation($input: AcceptAffiliateInvitationMutationInput!) {
    acceptAffiliateInvitationMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_PROFESSIONAL_INVITATION_MUTATION = gql`
  mutation AcceptProfessionalInvitationMutation(
    $input: AcceptProfessionalInvitationMutationInput!
  ) {
    acceptProfessionalInvitationMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_PROFESSIONAL_LINK_INVITATION_MUTATION = gql`
  mutation AcceptProfessionalLinkInvitationMutation(
    $input: AcceptProfessionalLinkInvitationMutationInput!
  ) {
    acceptProfessionalLinkInvitationMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPT_RESULTS_ACCESS_REQUEST_MUTATION = gql`
  mutation AcceptResultsAccessRequestMutation($input: AcceptResultsAccessRequestMutationInput!) {
    acceptResultsAccessRequestMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const PLATFORM_USER_PROFILE_MUTATION = gql`
  mutation PlatformUserProfileMutation($input: PlatformUserProfileMutationInput!) {
    platformUserProfileMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const GOALS_MUTATION = gql`
  mutation GoalsMutation($input: GoalsMutationInput!) {
    goalsMutation(input: $input) {
      platformUserProfile {
        id
        pk
        goals {
          id
          text
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const DIET_PREFERENCES_MUTATION = gql`
  mutation DietPreferencesMutation($input: DietPreferencesMutationInput!) {
    dietPreferencesMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PARTNER_USER_PROFILE_MUTATION = gql`
  mutation CreatePartnerUserProfileMutation($input: CreatePartnerUserProfileMutationInput!) {
    createPartnerUserProfileMutation(input: $input) {
      errors {
        field
        messages
      }
    }
  }
`;

export const CREATE_PARTNER_INVITATION_MUTATION = gql`
  mutation CreatePartnerInvitationMutation($input: CreatePartnerInvitationInput!) {
    createPartnerInvitationMutation(input: $input) {
      createdAffiliateLink
      createdResultsAccessLink
      errors {
        field
        messages
      }
    }
  }
`;

export const PARTNER_LINK_CODE_QUERY = gql`
  query PartnerShareLinkRedirectPageQuery($partnerLinkCode: String!) {
    checkIncomingPartnerLinkCode(partnerLinkCode: $partnerLinkCode) {
      hashId
      identifier
      type
      tier
    }
  }
`;

export const ACKNOWLEDGE_NOTIFICATION_MUTATION = gql`
  mutation AcknowledgeNotificationMutation($input: AcknowledgeNotificationMutationInput!) {
    acknowledgeNotificationMutation(input: $input) {
      ok
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      args
    }
  }
`;
