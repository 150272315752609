import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Box from "../components/Box";
import useWindowSize from "../hooks/use-window-size";

const ToggleSwitchContainer = styled(Box)`
  display: flex;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: relative;
  cursor: pointer;

  div {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border-radius: ${props => props.borderRadius}px;
  }
`;

export default function ToggleSwitch({
  enabledBackground,
  disabledBackground,
  data,
  onChange,
  height,
  value,
  ml,
  mr,
  borderColor
}) {
  const refs = useRef({});
  const [selected, setSelected] = useState(null);
  const [isBinary, setIsBinary] = useState(false);

  useEffect(() => {
    if (data.length === 2) {
      setIsBinary(true);
    }
  }, [data]);

  useEffect(() => {
    let selectedIndex = data.findIndex(item => item.value === value);
    setSelected(data[selectedIndex]);
  }, [value, data]);

  useWindowSize();
  // otherwise the widths calculated for the "transform" below are wrong
  // if the user resizes the browser

  return (
    <Box display="flex" ml={ml} mr={mr}>
      <ToggleSwitchContainer
        bg={value ? enabledBackground : disabledBackground}
        transition={value ? "none" : "background-color 200ms ease 100ms"}
        borderRadius={height}
        onClick={isBinary ? () => onChange(!value) : null}
        height={height}
        width={height * data.length}
        borderColor={selected?.borderColor || null}
        borderStyle={selected?.borderColor ? "solid" : "unset"}
        borderWidth={selected?.borderColor ? 1 : "unset"}
      >
        <Box
          p="1px"
          height="100%"
          top={0}
          left={0}
          width={`calc(${100 / data.length}%)`}
          borderRadius="100%"
          position="absolute"
          style={{
            transition: "transform 200ms ease 0s, width 100ms ease 0s",
            transform: `translate(${(function () {
              const index = data.findIndex(item => item.value === value);
              const itemsBefore = Object.values(refs.current).slice(0, index);
              const width = itemsBefore.reduce((accumulatedWidth, item) => {
                const elem = item.current;
                const { width } = elem.getBoundingClientRect();
                return accumulatedWidth + width;
              }, 0);
              return `${width}px`;
            })()})`
          }}
        >
          <Box
            color={selected?.color}
            bg={selected?.bg}
            fontFamily="gilroyBold"
            fontSize={12}
            height="100%"
            width="100%"
          >
            {selected?.label}
          </Box>
        </Box>
        {data.map((item, index) => (
          <Box
            data-component-name={item.dataComponentName}
            className="item"
            key={item.value}
            onClick={() => {
              if (item.value !== value) {
                onChange(item.value);
              }
            }}
            height={height}
            width={height}
            ref={elem => {
              if (!(index in refs.current)) {
                refs.current[index] = {
                  current: elem
                };
              } else {
                refs.current[index].current = elem;
              }
            }}
          ></Box>
        ))}
      </ToggleSwitchContainer>
    </Box>
  );
}

ToggleSwitch.defaultProps = {
  enabledBackground: "green",
  disabledBackground: "rgba(255, 255, 255, 0.30)",
  padding: 2,
  data: [
    {
      label: "No",
      value: false,
      bg: "dark",
      color: "white"
    },
    {
      label: "Yes",
      value: true,
      bg: "white",
      color: "dark"
    }
  ]
};
