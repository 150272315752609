import { useCallback, useMemo, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import { camelCase, get } from "lodash";

// Per: https://react-hook-form.com/docs/useform/seterror
// We must start with 'root' so that the error is doesn't prevent
// re-submission after the error has been corrected by the user
const NON_FIELD_ERROR_KEY = "root.nonFieldError";

function handleServerSideErrors(errors, incrementScrollToError, setError) {
  if (!errors.length) {
    return;
  }

  errors.forEach(error => {
    setError(error.field === "_All__" ? NON_FIELD_ERROR_KEY : camelCase(error.field), {
      type: "custom",
      message: error.messages[0]
    });
  });

  incrementScrollToError();
}

export default function useDjangoGraphqlForm({
  defaultValues,
  mutation: mutationGql,
  mutationOptions,
  mutationName,
  transformer,
  handleSuccess,
  handleFailure,
  mode
}) {
  const [scrollToError, setScrollToError] = useState(0);

  const incrementScrollToError = useCallback(() => {
    setScrollToError(scrollToError + 1);
  }, [scrollToError]);

  const [mutation] = useMutation(mutationGql, mutationOptions);
  const methods = useForm({
    defaultValues,
    mode
  });

  const transformerRef = useRef();
  transformerRef.current = transformer;

  const handleSuccessRef = useRef();
  handleSuccessRef.current = handleSuccess;
  const handleFailureRef = useRef();
  handleFailureRef.current = handleFailure;

  const {
    getValues,
    reset,
    setError,
    formState: { errors }
  } = methods;

  const onSubmit = useCallback(
    async input => {
      try {
        const resp = await mutation({
          variables: {
            input: transformerRef.current ? transformerRef.current(input) : input
          }
        });
        const data = resp.data;
        if (data[mutationName]) {
          if (!data[mutationName].errors.length) {
            if (handleSuccessRef.current) {
              handleSuccessRef.current({
                data,
                api: {
                  reset
                }
              });
            }
          } else if (data[mutationName].errors.length) {
            handleServerSideErrors(data[mutationName].errors, incrementScrollToError, setError);
            if (handleFailureRef.current) {
              handleFailureRef.current();
            }
          }
        }
      } catch (error) {
        console.log("Error encontered submitting the form", error);
        setError(NON_FIELD_ERROR_KEY, {
          type: "custom",
          message: `Something went wrong. Please try again later. (${error})`
        });
        if (handleFailureRef.current) {
          handleFailureRef.current(error);
        }
      }
    },
    [mutation, setError, reset, mutationName, incrementScrollToError]
  );

  const id = getValues("id");

  const nonFieldError = get(errors, NON_FIELD_ERROR_KEY)?.message;

  const api = useMemo(
    () => ({
      id,
      methods,
      nonFieldError,
      onSubmit,
      scrollToError
    }),
    [id, methods, nonFieldError, onSubmit, scrollToError]
  );

  return api;
}
