import { useCallback, useState } from "react";

import { gql, useMutation } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Loading from "components/Loading";
import Page from "components/Page";
import { PLATFORM_USER_PROFILE_QUERY } from "graphql/accounts";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { ORDER_FIELDS, ORDER_LIST_FIELDS, REMOVE_PAYMENT_METHOD } from "graphql/shop";
import { useDataLoader } from "hooks/useDataLoader";
import useListControls from "hooks/useListControls";
import { PanelBoxV2 } from "tpo/Boxes";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import Jumbotron from "tpo/Jumbotron";
import { List, ListContext } from "tpo/List";
import Modal from "tpo/Modal";
import PaginationWrapper from "tpo/Pagination";
import SortMenu from "tpo/SortMenu";
import Spacer from "tpo/Spacer";
import { Search } from "tpo/TextInput";
import ButtonV2 from "v2/Buttons";

import { OrderDetails, OrderDetailsContent } from "./OrderDetailPage";
import OrderIcon from "./OrderIcon";

function SavedPaymentMethod() {
  const { data, loading } = useDataLoader({
    query: PLATFORM_USER_PROFILE_QUERY
  });
  const [removePaymentMethodMutation] = useMutation(REMOVE_PAYMENT_METHOD);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const removePaymentMethod = useCallback(() => {
    removePaymentMethodMutation({
      refetchQueries: [
        {
          query: PLATFORM_USER_PROFILE_QUERY
        }
      ]
    })
      .then(() => setIsSubmitting(false))
      .catch(error => {
        setIsSubmitting(false);
        console.log("Error encountered while removing payment method", error);
        Sentry.captureException(error);
      });
  }, [removePaymentMethodMutation]);

  const platformUserProfile = data?.platformUserProfile;

  return (
    <PanelBoxV2
      maxWidth={760}
      outer={{
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        px: [20, 20, "5.5vw"],
        bg: "white"
      }}
      stacked
      gap={40}
    >
      <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
        Saved payment card
      </Box>
      {loading && <Loading />}
      {!!data && platformUserProfile?.stripeCardPaymentMethodId ? (
        <Group alignItems="center" justifyContent="space-between">
          <Box fontFamily="gilroyBold" fontSize={16} data-testid="paymentMethodDetails">
            {platformUserProfile.stripeCardBrand.toUpperCase()} ending in{" "}
            {platformUserProfile.stripeCardLast4}, expiry {platformUserProfile.stripeCardExpMonth}/
            {platformUserProfile.stripeCardExpYear}
          </Box>
          <ButtonV2
            disabled={isSubmitting}
            color="red"
            onClick={removePaymentMethod}
            rightIcon={<ChevronComponent />}
            data-component-name="RemovePaymentMethodButton"
          >
            Delete card
          </ButtonV2>
        </Group>
      ) : (
        <Box data-testid="noSavedCardFound">No payment card saved.</Box>
      )}
    </PanelBoxV2>
  );
}

const ORDER_DETAIL_VIEW_QUERY = gql`
  query OrderDetailViewQuery($id: ID!) {
    order(id: $id) {
      ...OrderFields
    }
  }
  ${ORDER_FIELDS}
`;

const ORDER_HISTORY_PAGE_QUERY = gql`
  query OrderHistoryPageQuery($page: Int, $orderBy: String, $search: String) {
    ordersPagination(page: $page, orderBy: $orderBy, search: $search) {
      items {
        ...OrderListFields
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${ORDER_LIST_FIELDS}
  ${PAGE_INFO_FIELDS}
`;

function OrdersHistory() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [page, setPage] = useState(1);
  const controls = useListControls({
    defaultSort: "-checkout_date",
    sortOptions: [
      {
        label: "A - Z",
        value: "pk"
      },
      {
        label: "Z - A",
        value: "-pk"
      },
      {
        label: "Oldest To Newest",
        value: "checkout_date"
      },
      {
        label: "Newest To Oldest",
        value: "-checkout_date"
      }
    ]
  });

  const { data, loading } = useDataLoader({
    query: ORDER_HISTORY_PAGE_QUERY,
    variables: {
      page: page,
      orderBy: controls.sort,
      search: controls.search
    }
  });

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20, 20, "5.5vw"]
        }}
      >
        <Box
          bg="white"
          borderRadius={5}
          maxWidth={1280}
          pt={[30, 30, 60]}
          pb={[50, 50, 80]}
          px={[20, 20, 40]}
          mx="auto"
        >
          <Box maxWidth={1020} mx="auto">
            <Group gap={20}>
              <OrderIcon width={[30, 30, 40]} />
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                Order History
              </Box>
            </Group>
            <Spacer py={[2, 2, 20]} />
            <Box
              display="flex"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
              alignItems="flex-start"
              gap={20}
            >
              <Search
                value={controls.search}
                onChange={controls.setSearch}
                maxWidth={[350]}
                minWidth={310}
                width="100%"
                bg="haze"
                styles={{
                  wrapper: {}
                }}
              />
              <SortMenu
                value={controls.sort}
                setValue={controls.setSort}
                open={controls.sortMenuOpen}
                setOpen={controls.setSortMenuOpen}
                options={controls.sortOptions}
                styles={{
                  button: {
                    color: "dark"
                  }
                }}
                placeholder="Sort By"
              />
            </Box>
            <Spacer py={[2, 2, 30]} />
            <List items={data?.ordersPagination?.items} loading={loading}>
              <ListContext.Consumer>
                {items =>
                  items?.map(o => (
                    <Box
                      key={o.id}
                      borderBottomStyle="solid"
                      borderBottomWidth={1}
                      borderBottomColor="haze"
                      data-component-name="Order"
                    >
                      <OrderDetails
                        orderNo={o.id}
                        orderDate={o.checkoutDate}
                        user={o.user}
                        shippingFullName={o.shippingFullName}
                      />
                      <Group>
                        <ButtonV2
                          rightIcon={<ChevronComponent fill="black" />}
                          variant="transparent"
                          onClick={() => setSelectedOrder(o)}
                          sx={{
                            ml: "auto",
                            fontSize: 10
                          }}
                        >
                          order details
                        </ButtonV2>
                      </Group>
                    </Box>
                  ))
                }
              </ListContext.Consumer>
            </List>
            <Spacer py={[2, 2, 20]} />
            <PaginationWrapper
              variant={["mobile", "mobile", "default"]}
              pageInfo={data?.ordersPagination?.pageInfo}
              onChange={setPage}
            />
          </Box>
        </Box>
      </PanelBoxV2>
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="white"
        show={!!selectedOrder}
        close={() => setSelectedOrder()}
        mode={["fullScreen", "fullScreen", "centered"]}
        data-component-name="OrderDetailsModal"
      >
        <DataLoader
          query={ORDER_DETAIL_VIEW_QUERY}
          variables={{ id: selectedOrder?.id }}
          render={({
            order: {
              id,
              updated,
              shippingFullName,
              shippingAddress1,
              shippingAddress2,
              shippingTownCity,
              shippingPostalCode,
              shippingCountry,
              testItems,
              supplementItems,
              postageCosts,
              discountedTotal,
              user
            }
          }) => (
            <OrderDetailsContent
              id={id}
              updated={updated}
              shippingFullName={shippingFullName}
              shippingAddress1={shippingAddress1}
              shippingAddress2={shippingAddress2}
              shippingTownCity={shippingTownCity}
              shippingPostalCode={shippingPostalCode}
              shippingCountry={shippingCountry}
              testItems={testItems}
              supplementItems={supplementItems}
              postageCosts={postageCosts}
              discountedTotal={discountedTotal}
              user={user}
            />
          )}
        />
      </Modal>
    </>
  );
}

export default function OrderHistoryPage() {
  return (
    <Page bg="haze">
      <Jumbotron title="Orders" />
      <SavedPaymentMethod />
      <OrdersHistory />
    </Page>
  );
}
