import { useContext, useRef } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import PartnerContext from "contexts/PartnerContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import ControlledCheckboxGroup from "tpo/ControlledCheckboxGroup";
import ControlledFormField from "tpo/ControlledFormField";
import EditFormButtons from "tpo/EditFormButtons";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import Stack from "tpo/Stack";

import { PROFILE_FORM_FIELDS } from "../../../../tpo/partnerDashboard/common/graphql/types";

function initializePractitionerProfileForm(partnerUserProfile) {
  return {
    id: partnerUserProfile.pk,
    description: partnerUserProfile.description.split(",").filter(str => str),
    descriptionOther: partnerUserProfile.descriptionOther,
    qualifications: partnerUserProfile.qualifications,
    specialisms: partnerUserProfile.specialisms.split(",").filter(str => str),
    specialismsOther: partnerUserProfile.specialismsOther,
    instagram: partnerUserProfile.instagram,
    linkedin: partnerUserProfile.linkedin,
    youtube: partnerUserProfile.youtube,
    websiteBlog: partnerUserProfile.websiteBlog
  };
}

const EDIT_PRACTITIONER_PROFILE_MUTATION = gql`
  mutation EditPractitionerProfile($input: PartnerPractitionerProfileMutationInput!) {
    partnerPractitionerProfileMutation(input: $input) {
      partnerUserProfile {
        ...ProfileFormFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${PROFILE_FORM_FIELDS}
`;

export default function PractitionerProfileForm() {
  const partnerUserProfile = useContext(PartnerContext);

  const submitButtonRef = useRef();

  const { id, methods, nonFieldError, onSubmit } = useDjangoGraphqlForm({
    transformer: input => ({
      ...input,
      description: input.description.join(","),
      specialisms: input.specialisms.join(",")
    }),
    mutation: EDIT_PRACTITIONER_PROFILE_MUTATION,
    mutationName: "partnerPractitionerProfileMutation",
    defaultValues: initializePractitionerProfileForm(partnerUserProfile),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(
        initializePractitionerProfileForm(
          data.partnerPractitionerProfileMutation.partnerUserProfile
        )
      );
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  if (!id) {
    // this can't happen unless the field is accidentally
    // removed from the query
    // if id was missing however it would try to create a new instance
    // server side instead of editing the existing for this ID
    return null;
  }

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <PanelBoxV2
        maxWidth={1532}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20],
          bg: "white"
        }}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PanelBoxV2 maxWidth={760} stacked gap={40}>
            <FormControl
              as="fieldset"
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                  What best describes you?
                  <Asterisk />
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Select as many as you like
                </Box>
              }
              fields={
                <>
                  <Stack gap={20}>
                    <ControlledCheckboxGroup
                      Container={Stack}
                      containerProps={{
                        gap: 20
                      }}
                      name="description"
                      values={[
                        {
                          label: "Functional Medicine Practitioner",
                          value: "Functional Medicine Practitioner"
                        },
                        {
                          label: "Doctor",
                          value: "Doctor"
                        },
                        {
                          label: "Naturopath",
                          value: "Naturopath"
                        },
                        {
                          label: "Dietitian",
                          value: "Dietitian"
                        },
                        {
                          label: "Nutritionist",
                          value: "Nutritionist"
                        },
                        {
                          label: "Nutritional Therapist",
                          value: "Nutritional Therapist"
                        },
                        {
                          label: "Health Coach",
                          value: "Health Coach"
                        }
                      ]}
                    />
                  </Stack>
                  <ControlledFormField
                    name="descriptionOther"
                    label="Other (please give details)"
                    Component={FloatingLabelInput}
                  />
                </>
              }
            />
            <FormControl
              label={
                <Box as="h2" fontFamily="gilroyBold" fontSize={28} lineHeight="36px">
                  Qualifications
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Please give details of professional qualifications, or professional bodies you are
                  registered with
                </Box>
              }
            >
              <ControlledFormField
                name="qualifications"
                label="Qualifications"
                Component={FloatingLabelInput}
              />
            </FormControl>
            <FormControl
              as="fieldset"
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                  Expertise
                  <Asterisk />
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Select as many as you like
                </Box>
              }
              fields={
                <>
                  <ControlledCheckboxGroup
                    Container={Stack}
                    containerProps={{
                      gap: 20
                    }}
                    name="specialisms"
                    values={[
                      {
                        label: "Blood sugar regulation",
                        value: "Blood sugar regulation"
                      },
                      {
                        label: "Digestive health",
                        value: "Digestive health"
                      },
                      {
                        label: "Immune health",
                        value: "Immune health"
                      },
                      {
                        label: "Detoxification pathways",
                        value: "Detoxification pathways"
                      },
                      {
                        label: "Cardiovascular health",
                        value: "Cardiovascular health"
                      },
                      {
                        label: "Energy regulation",
                        value: "Energy regulation"
                      },
                      {
                        label: "Hormones",
                        value: "Hormones"
                      },
                      {
                        label: "Nutritional health",
                        value: "Nutritional health"
                      },
                      {
                        label: "Performance",
                        value: "Performance"
                      }
                    ]}
                  />

                  <FormControl
                    label={
                      <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                        Additional details on your work
                      </Box>
                    }
                  >
                    <ControlledFormField
                      name="specialismsOther"
                      label="Other (please give details)"
                      Component={FloatingLabelInput}
                    />
                  </FormControl>
                </>
              }
            />
            <FormControl
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                  Social media
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Please enter at least one of your social media links.
                </Box>
              }
            >
              <Stack gap={20}>
                <ControlledFormField
                  name="instagram"
                  label="Instagram"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="linkedin"
                  label="Linkedin"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="youtube"
                  label="Youtube"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="websiteBlog"
                  label="Website / Blog"
                  Component={FloatingLabelInput}
                />
              </Stack>
            </FormControl>
            <Box>
              <Center gap={20}>
                <EditFormButtons />
              </Center>
              {nonFieldError && (
                <Center color="red" py={20} mt={20}>
                  {nonFieldError}
                </Center>
              )}
            </Box>
          </PanelBoxV2>
        </form>
      </PanelBoxV2>
    </FormProvider>
  );
}
