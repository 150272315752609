import { useHistory, useLocation } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { useOrganisationContext } from "contexts/organisations/OrganisationContext";
import { PRACTITIONER } from "core/constants";
import { theme } from "core/theme";
import { DASHBOARD_HOME_URL, PARTNERS_DASHBOARD_URL } from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";
import RegenerusLogo from "images/RegenerusLogo";
import styled, { createGlobalStyle } from "styled-components";

import Center from "./Center";
import Jumbotron from "./Jumbotron";
import SegmentedControl from "./SegmentedControl";
import Spacer from "./Spacer";
import Stack from "./Stack";

/**
 * Introduced to deal with the new toggle for switching
 * between the user and partner dashboards.
 *
 * We don't want it as an element inside each page because it
 * is then removed when switching.
 */

const FullRegenerusLogo = styled(Stack)`
  position: static;

  @media (min-width: 1280px) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
  }
`;

export default function CrossPageHeader({ mt }) {
  const history = useHistory();
  const location = useLocation();
  const { setOrganisationId, availableOrganisations } = useOrganisationContext();

  const dashboardUrl = location.pathname === DASHBOARD_HOME_URL;
  const partnerUrl = usePartnerPageCheck();
  const cmpUrl = location.pathname.startsWith("/patients");
  const partnersRoot = location.pathname === "/partners";
  const affiliateUrl = location.pathname.startsWith("/partners/affiliate");
  const practitionerUrl = location.pathname.startsWith("/partners/practitioner");

  const { userInfo } = useAppState();

  if (partnersRoot || affiliateUrl || practitionerUrl || (!dashboardUrl && !partnerUrl) || cmpUrl) {
    return null;
  }

  if (!userInfo || !userInfo?.user) return null;

  if (!userInfo.user.partnerUserProfile?.id) {
    return <Jumbotron title="Dashboard" mt={mt} />;
  }

  let bg = dashboardUrl ? "purple" : "partners";

  return (
    <Box bg={bg} px={20} mt={mt} data-component-name="CrossPageHeader" position="relative">
      <Spacer py={[25, 25, 20]} />
      {partnerUrl && userInfo.user.partnerUserProfile.tier === PRACTITIONER && (
        <FullRegenerusLogo>
          <Box color="dark" fontFamily="gilroyRegular" fontSize={18}>
            Powered by
          </Box>
          <RegenerusLogo color={theme.colors.dark} />
        </FullRegenerusLogo>
      )}
      <Spacer py={[25, 25, 0]} />
      <Center px={20}>
        <SegmentedControl
          data={[
            {
              label: "My Omnos",
              value: DASHBOARD_HOME_URL
            },
            {
              label: "Partners",
              value: PARTNERS_DASHBOARD_URL
            }
          ]}
          onChange={val => {
            if (val === DASHBOARD_HOME_URL) {
              setOrganisationId(null);
            } else {
              setOrganisationId(availableOrganisations?.[0]?.id);
            }
            return history.push(val);
          }}
          value={dashboardUrl ? DASHBOARD_HOME_URL : PARTNERS_DASHBOARD_URL}
          height={55}
        />
      </Center>

      <Spacer py={[25, 25, 20]} />
    </Box>
  );
}

CrossPageHeader.defaultProps = {
  bg: "purple"
};

export const HideCrossPageHeader = createGlobalStyle`
  [data-component-name="CrossPageHeader"] {
    display: none;
  }
`;
