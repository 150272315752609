import Box from "components/Box";

import Spacer from "./Spacer";
import Stack from "./Stack";

export default function FormControl({
  as = "div",
  label,
  labelProps,
  helpText,
  children,
  fields,
  spaceAboveChildren
}) {
  const hasChildren = !!(children || fields);

  let actualChildren = children;

  if (fields) {
    actualChildren = <Stack gap={20}>{fields}</Stack>;
  }

  return (
    <Box as={as} border={0} p={0} m={0}>
      {as === "fieldset" ? (
        <Box as="legend" {...labelProps}>
          {label}
        </Box>
      ) : (
        label
      )}
      {label && helpText && <Spacer py={1} />}
      {helpText}
      {(label || helpText) && hasChildren && <Spacer pt={spaceAboveChildren} />}
      {actualChildren}
    </Box>
  );
}

FormControl.defaultProps = {
  spaceAboveChildren: 4
};
