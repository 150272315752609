import React from "react";

import { ReactComponent as Logo } from "../images/logo.svg";

export default function MaintenanceMode() {
  const headingThreeProps = {
    position: "relative",
    alignSelf: "stretch",
    fontFamily: '"Gilroy-Bold", Helvetica',
    fontWeight: 700,
    color: "#ffffff",
    fontSize: "36px",
    textAlign: "center",
    letterSpacing: "0px",
    lineHeight: "100%",
    fontStyle: "normal"
  };

  const headingFourProps = {
    position: "relative",
    alignSelf: "stretch",
    fontFamily: '"Gilroy-Bold", Helvetica',
    fontWeight: 700,
    color: "#ffffff",
    fontSize: "28px",
    textAlign: "center",
    letterSpacing: "0px",
    lineHeight: "129.99999523162842%",
    fontStyle: "normal"
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        zIndex: "9999",
        backgroundColor: "rgba(23, 34, 77, 1)"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "1117px",
          maxWidth: "960px",
          margin: "0px auto",
          alignItems: "center",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
            padding: "0px 20px",
            position: "relative",
            alignSelf: "stretch",
            width: "100%",
            flex: "0 0 auto"
          }}
        >
          <Logo style={{ height: "169.08px" }} />
          <div style={headingThreeProps}>OFFLINE FOR MAINTENANCE</div>
          <p style={headingFourProps}>We will be back online shortly</p>
        </div>
      </div>
    </div>
  );
}
