import { Redirect, useParams } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Grid from "components/Grid";
import Page from "components/Page";
import { useBasketContext } from "contexts/BasketContext";
import { ChooseClinicProvider } from "contexts/ChooseClinicProvider";
import { useTestProductModalContext } from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { ERROR_404_URL } from "core/urls";
import {
  CONSUMER_TEST_PRODUCT_DETAIL_PAGE_QUERY,
  CONSUMER_TEST_PRODUCT_OPTION_FIELDS,
  GET_CONSUMER_CLINICS
} from "graphql/shop";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Modal from "tpo/Modal";
import Stack from "tpo/Stack";

import { ChooseClinic } from "./BaseTestProductModal";
import HowItWorksPanel from "./HowItWorksPanel";
import TestProductDetailContent from "./ProductDetail";
import TestProductOptions from "./TestProductOptions";
import TruncatedTestProductDetailContent from "./TruncatedProductDetail";
import {
  DISPLAY_CONTEXT_ALWAYS_VISIBLE,
  DISPLAY_CONTEXT_B2B_ONLY,
  DISPLAY_CONTEXT_B2C_ONLY
} from "./constants";
import showFullDetails from "./utils/showFullDetails ";

export const TRUNCATED_TEST_PRODUCT_VIEW_BG = "haze";
export const FULL_TEST_PRODUCT_VIEW_BG = "haze";

export function getCarouselImages(product) {
  return [
    product.content.carouselImage1,
    product.content.carouselImage2,
    product.content.carouselImage3
  ].filter(url => url);
}

export function TestProductDetail({ testProduct, userTestRecommendation }) {
  const {
    selectedTestProductOptionId,
    selectTestProductOptionId,
    testProductOption
  } = useTestProductModalContext();
  const { addItemToBasket } = useBasketContext();
  const { setBasketOpen } = useAppState();

  const carouselImages = getCarouselImages(testProduct);

  const testDataFilesWithSamples = testProduct.testDataFiles
    .filter(tdf => tdf.sampleFile)
    .map(tdf => ({
      id: tdf.id,
      name: tdf.name,
      url: tdf.sampleFile,
      extension: tdf.fileType.extension,
      contentType: tdf.fileType.contentType
    }));

  let content = null;

  const fullView = showFullDetails(testProduct);

  if (fullView) {
    content = (
      <TestProductDetailContent
        sampleReports={
          testDataFilesWithSamples.length
            ? testDataFilesWithSamples
            : [
                {
                  id: "reportPdf",
                  name: "Report Pdf",
                  url: testProduct.reportPdf,
                  extension: "pdf",
                  contentType: "application/pdf"
                }
              ]
        }
        status={testProduct.status}
        compositeId={testProduct.compositeId}
        practitionerSupportRequired={testProduct.productAudience === "B2B"}
        productAudience={testProduct.productAudience}
        carouselImages={carouselImages}
        name={testProduct.name}
        description={testProduct.content.description}
        shortDescription={testProduct.content.shortDescription}
        categories={testProduct.content.categories}
        sampleTypes={testProduct.sampleTypes}
        numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
        processingTime={testProduct.content.processingTime}
        options={
          testProduct.options.length ? (
            <TestProductOptions>
              {testProduct.options.map((option, index) => {
                const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
                return (
                  <TestProductOptions.Option
                    key={option.id}
                    status={testProduct.status}
                    id={option.id}
                    compositeId={option.compositeId}
                    name={option.testProductOptionFamily.name}
                    description={option.testProductOptionFamily.description}
                    price={
                      hasClinics ? (
                        <Stack gap={0}>
                          <Currency
                            prefix="FROM"
                            prefixFontFamily="gilroyBold"
                            prefixFontSize={16}
                            value={option.consumerClinicsInfo.fromPrice}
                            stacked
                            fontSize={44}
                          />
                          <Box fontFamily="gilroyMedium" fontSize={14}>
                            Includes clinic fee
                          </Box>
                        </Stack>
                      ) : (
                        option.retailCurrentPrice
                      )
                    }
                    practitionerSupportRequired={testProduct.productAudience === "B2B"}
                    lastOption={index === testProduct.options.length - 1}
                    hasClinics={hasClinics}
                    onFindNearestClinic={() => {
                      selectTestProductOptionId(option.id);
                    }}
                    onAddToBasket={() => {
                      setBasketOpen(true);
                      addItemToBasket({
                        compositeId: option.compositeId
                      });
                    }}
                  />
                );
              })}
            </TestProductOptions>
          ) : null
        }
        howItWorksPanel={
          testProduct.content.howItWorksPanel &&
          (testProduct.content.collectingYourSampleImage ||
            testProduct.content.collectingYourSampleVideo) ? (
            <Grid
              gridTemplateColumns={["1fr", "1fr", "1fr 1fr"]}
              px={[20, 20, 225]}
              pt={[30, 30, 60]}
              pb={[50, 50, 80]}
              gridRowGap={40}
            >
              <HowItWorksPanel
                title={testProduct.content.howItWorksPanel.title}
                body={testProduct.content.howItWorksPanel.body}
                collectingYourSampleImage={testProduct.content.collectingYourSampleImage}
                collectingYourSampleVideo={testProduct.content.collectingYourSampleVideo}
              />
            </Grid>
          ) : null
        }
        price={testProduct.retailCurrentPrice}
        previousPrice={testProduct.retailFullPrice}
        userTestRecommendation={userTestRecommendation}
        slug={testProduct.slug}
        informationPanels={testProduct.content.informationPanels.filter(
          panel =>
            panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
            panel.displayContext === DISPLAY_CONTEXT_B2C_ONLY
        )}
        biomarkersTested={testProduct.content.biomarkersTested}
        relatedSymptoms={testProduct.content.relatedSymptoms}
        collectingYourSampleImage={testProduct.content.collectingYourSampleImage}
        collectingYourSampleVideo={testProduct.content.collectingYourSampleVideo}
        testType={testProduct.content.testType}
        onAddToBasket={() => {
          addItemToBasket({
            compositeId: testProduct.compositeId
          });
          setBasketOpen(true);
        }}
      />
    );
  } else {
    content = (
      <TruncatedTestProductDetailContent
        sampleReports={
          testDataFilesWithSamples.length
            ? testDataFilesWithSamples
            : [
                {
                  id: "reportPdf",
                  name: "Report Pdf",
                  url: testProduct.reportPdf,
                  extension: "pdf",
                  contentType: "application/pdf"
                }
              ]
        }
        status={testProduct.status}
        compositeId={testProduct.compositeId}
        practitionerSupportRequired={testProduct.productAudience === "B2B"}
        carouselImages={carouselImages}
        productAudience={testProduct.productAudience}
        name={testProduct.name}
        description={testProduct.content.description}
        shortDescription={testProduct.content.shortDescription}
        categories={testProduct.content.categories}
        sampleTypes={testProduct.sampleTypes}
        numOfBiomarkersTested={testProduct.content.numOfBiomarkersTested}
        processingTime={testProduct.content.processingTime}
        options={
          testProduct.options.length ? (
            <TestProductOptions>
              {testProduct.options.map((option, index) => {
                const hasClinics = !!option.consumerClinicsInfo?.clinics?.length;
                return (
                  <TestProductOptions.Option
                    status={testProduct.status}
                    key={option.id}
                    id={option.id}
                    compositeId={option.compositeId}
                    name={option.testProductOptionFamily.name}
                    description={option.testProductOptionFamily.description}
                    price={
                      hasClinics ? (
                        <Stack gap={0}>
                          <Currency
                            prefix="FROM"
                            prefixFontFamily="gilroyBold"
                            prefixFontSize={16}
                            value={option.consumerClinicsInfo.fromPrice}
                            stacked
                            fontSize={44}
                          />
                          <Box fontFamily="gilroyMedium" fontSize={14}>
                            Includes clinic fee
                          </Box>
                        </Stack>
                      ) : (
                        option.retailCurrentPrice
                      )
                    }
                    practitionerSupportRequired={testProduct.productAudience === "B2B"}
                    lastOption={index === testProduct.options.length - 1}
                    hasClinics={hasClinics}
                    onFindNearestClinic={() => {
                      selectTestProductOptionId(option.id);
                    }}
                    onAddToBasket={() => {
                      setBasketOpen(true);
                      addItemToBasket({
                        compositeId: option.compositeId
                      });
                    }}
                  />
                );
              })}
            </TestProductOptions>
          ) : null
        }
        price={testProduct.retailCurrentPrice}
        previousPrice={testProduct.retailFullPrice}
        slug={testProduct.slug}
        informationPanels={testProduct.content.informationPanels.filter(
          panel =>
            panel.displayContext === DISPLAY_CONTEXT_ALWAYS_VISIBLE ||
            panel.displayContext === DISPLAY_CONTEXT_B2B_ONLY
        )}
        biomarkersTested={testProduct.content.biomarkersTested}
        relatedSymptoms={testProduct.content.relatedSymptoms}
        testType={testProduct.content.testType}
        onAddToBasket={() => {
          addItemToBasket({
            compositeId: testProduct.compositeId
          });
          setBasketOpen(true);
        }}
      />
    );
  }

  const show =
    selectedTestProductOptionId &&
    testProductOption &&
    testProductOption.consumerClinicsInfo?.clinics?.length;

  return (
    <>
      {content}
      <Modal
        maxWidth={1020}
        closeButton
        headerProps={{
          p: [2, 2, 20],
          pb: 20
        }}
        bg="white"
        show={show}
        close={() => selectTestProductOptionId(undefined)}
        mode={["fullScreen", "fullScreen", "centered"]}
      >
        <ChooseClinic
          onAddToBasket={({ clinic }) => {
            addItemToBasket({
              compositeId: testProductOption.compositeId,
              clinicLocationId: clinic.id
            });
            setBasketOpen(true);
            selectTestProductOptionId(undefined);
          }}
          query={GET_CONSUMER_CLINICS}
          testProductOptionPriceField="retailCurrentPrice"
          clinicLocationsField="consumerClinicLocations"
        />
      </Modal>
    </>
  );
}

export default function TestProductDetailPage() {
  const { slug } = useParams();

  return (
    <DataLoader
      query={CONSUMER_TEST_PRODUCT_DETAIL_PAGE_QUERY}
      variables={{
        currency: "GBP",
        slug
      }}
      render={({ consumerTestProduct: testProduct, userTestRecommendation }) =>
        testProduct ? (
          <Page>
            <TestProductOptionProvider
              testProductOptionFragment={CONSUMER_TEST_PRODUCT_OPTION_FIELDS}
              testProductOptionFragmentName="ConsumerTestProductOptionFields"
              testProductOptionFragmentType="TestProductOptionType"
            >
              <ChooseClinicProvider>
                <TestProductDetail
                  testProduct={testProduct}
                  userTestRecommendation={userTestRecommendation}
                />
              </ChooseClinicProvider>
            </TestProductOptionProvider>
          </Page>
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      }
    />
  );
}
