export default function Plus({ color }) {
  return (
    <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.054 3.102H6.084V0.149999H3.906V3.102H0.954V5.28H3.906V8.25H6.084V5.28H9.054V3.102Z"
        fill={color}
      />
    </svg>
  );
}
