import { useEffect, useState } from "react";

import { ExternalTextLink } from "components/Links";
import { getAPIBase } from "core/config";

const { default: Box } = require("components/Box");
const { default: Group } = require("tpo/Group");
const { default: Stack } = require("tpo/Stack");
const { default: ButtonV2 } = require("v2/Buttons");

export default function OrganisationOrderSummary({ order }) {
  const [stripeDetails, setStripeDetails] = useState();
  const STATUS_OPTIONS = {
    unfilled: {
      color: "black",
      variant: "outline",
      borderRadius: "100px",
      borderWidth: "2px"
    },
    filled: {
      color: "black",
      variant: "pill"
    },
    success: {
      color: "green",
      variant: "pill"
    },
    failure: {
      color: "red",
      variant: "pill"
    },
    notify: {
      color: "purple",
      variant: "pill"
    }
  };

  const STATUSES = {
    "awaiting dispatch": STATUS_OPTIONS["unfilled"],
    dispatched: STATUS_OPTIONS["filled"],
    "at lab": STATUS_OPTIONS["unfilled"],
    failed: STATUS_OPTIONS["failure"],
    cancelled: STATUS_OPTIONS["failure"],
    "review results": STATUS_OPTIONS["notify"],
    "results released": STATUS_OPTIONS["success"],
    invoiced: STATUS_OPTIONS["filled"],
    "awaiting payment": STATUS_OPTIONS["unfilled"],
    "patient to pay": STATUS_OPTIONS["failure"],
    unpaid: STATUS_OPTIONS["failure"],
    "over due": STATUS_OPTIONS["failure"],
    "partial refund": STATUS_OPTIONS["unfilled"],
    refunded: STATUS_OPTIONS["filled"],
    credit: STATUS_OPTIONS["filled"],
    "patient paid": STATUS_OPTIONS["success"],
    paid: STATUS_OPTIONS["success"]
  };

  useEffect(() => {
    if (order.sourceBasket.paymentCheckoutSession) {
      if (order.sourceBasket.paymentCheckoutSession.invoiceUrl) {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink href={`${order.sourceBasket.paymentCheckoutSession.invoiceUrl}`}>
              View Invoice
            </ExternalTextLink>
          )
        });
      } else {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink href={`${order.sourceBasket.paymentCheckoutSession.url}`}>
              Payment Link
            </ExternalTextLink>
          )
        });
      }
    } else if (order.sourceBasket.paymentInvoice) {
      return setStripeDetails({
        label: "Payment:",
        value: (
          <ExternalTextLink href={order.sourceBasket.paymentInvoice.url}>
            View Invoice
          </ExternalTextLink>
        )
      });
    } else if (order.sourceBasket.payee === "patient") {
      return setStripeDetails({
        label: "Payment:",
        value: (
          <ExternalTextLink href={`${getAPIBase()}/payments/checkout/${order.id}`}>
            Payment Link
          </ExternalTextLink>
        )
      });
    } else {
      return setStripeDetails({
        label: "Payment:",
        value: "N/A"
      });
    }
  }, [order]);

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      pt={4}
      gap={20}
    >
      <Stack gap={10}>
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {order.id}
          </Box>
        </Group>
        {stripeDetails && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={16}>
              {stripeDetails.label}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={16}>
              {stripeDetails.value}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Order Date:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {new Date(order.checkoutDate).toLocaleDateString()}
          </Box>
        </Group>
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={16}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={16}>
            {order.user.firstName} {order.user.lastName}
          </Box>
        </Group>
      </Stack>
      <Group gap={10} alignSelf="flex-start" flexWrap="wrap">
        <ButtonV2
          key={order.sourceBasket.paymentStatus}
          py="8px"
          px={16}
          fontSize={10}
          {...STATUSES[order.sourceBasket.paymentStatus]}
          style={{
            cursor: "default"
          }}
        >
          {order.sourceBasket.paymentStatus}
        </ButtonV2>
        {/* <ButtonV2
          key={order.sourceBasket.orderStatus}
          py="8px"
          px={16}
          fontSize={10}
          {...STATUSES[order.sourceBasket.orderStatus]}
          style={{
            cursor: "default"
          }}
        >
          {order.sourceBasket.orderStatus}
        </ButtonV2> */}
      </Group>
    </Box>
  );
}
