const { default: Group } = require("tpo/Group");
const { default: ButtonV2 } = require("v2/Buttons");

function PillsGroup({ children, ...props }) {
  return (
    <Group flexWrap="wrap" gap="5px 10px" {...props}>
      {children}
    </Group>
  );
}

function Pill({ children, ...props }) {
  return (
    <ButtonV2
      variant="pill"
      py="8px"
      px={16}
      fontSize={10}
      maxHeight={24}
      lineHeight="10px"
      {...props}
    >
      {children}
    </ButtonV2>
  );
}

export default Object.assign(
  {},
  {
    Group: PillsGroup,
    Pill
  }
);
