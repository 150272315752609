import gql from "graphql-tag";

export const ORGANISATION_FIELDS = gql`
  fragment OrganisationFields on CmpOrganisationType {
    id
    name
    automaticInvoicingEnabled
    chargesEnabled
    onboardingUrl
    onboardingStatus
    disabled
    disabledReason
    contactEmail
    contactNumber
    website
    companyNumber
    vatNumber
    line1
    line2
    city
    postcode
    country {
      id
      name
      isoCode
    }
    patients {
      id
      platformUserProfile {
        id
        pk
        user {
          id
          firstName
          lastName
          email
        }
        dateOfBirth
        gender
      }
    }
  }
`;

export const ORGANISATION_BASKET_FIELDS = gql`
  fragment OrganisationBasketFields on OrganisationBasketType {
    id
    organisation {
      id
      name
    }
    practitioner {
      id
      user {
        firstName
        lastName
        email
      }
    }
    patient {
      id
      pk
      user {
        firstName
        lastName
        email
      }
    }
    shippingAddress {
      id
      name
      line1
      line2
      city
      postcode
      country {
        id
        name
        isoCode
      }
    }
    currency {
      id
      name
      isoCode
    }
    payee
    customsConfirmation
    purchaseAsStock
    commissionPercentageSplit
    basketShippingCost
    basketRrpTotalPrice
    basketTotalPrice
    basketTotalCommission
    productLineItems {
      id
      product {
        id
        name
        productCode
      }
      productOption {
        id
        testProductOptionFamily {
          name
        }
      }
      clinicLocation {
        id
        address
        clinic {
          name
        }
      }
      quantity
      fullPricePerUnit
      rrpPerUnit
      pricePerUnit
      clinicFeesPerUnit
    }
    supplementLineItems {
      id
      supplement {
        id
        name
      }
      quantity
      fullPricePerUnit
      rrpPerUnit
      pricePerUnit
    }
  }
`;
