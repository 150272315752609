import { Button } from "../deprecated/Buttons";

export default function Tab({
  tab,
  selectedTab,
  tabs,
  onClick,
  borderRadiusTop,
  borderRadiusBottom,
  px,
  py,
  withBorder
}) {
  let radiusProps = {};
  if (borderRadiusTop) {
    radiusProps = {
      ...radiusProps,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5
    };
  }
  if (borderRadiusBottom) {
    radiusProps = {
      ...radiusProps,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5
    };
  }

  let borderProps = {};

  if (borderRadiusTop && withBorder) {
    borderProps = {
      ...borderProps,
      borderBottomStyle: "solid",
      borderBottomColor: "haze",
      borderBottomWidth: 1
    };
  }

  if (borderRadiusBottom && withBorder) {
    borderProps = {
      ...borderProps,
      borderTopStyle: "solid",
      borderTopColor: "haze",
      borderTopWidth: 1
    };
  }

  return (
    <Button
      bg={tab.slug === selectedTab ? "dark" : "white"}
      color={tab.slug === selectedTab ? "white" : "dark"}
      fontSize={[14]}
      py={py}
      px={px}
      {...radiusProps}
      borderRightStyle={tabs.slice(-1)?.[0]?.slug !== tab.slug ? "solid" : null}
      borderRightColor={tab.slug === selectedTab ? "dark" : "haze"}
      borderWidth={1}
      display="inline"
      ml={tabs?.[0]?.slug === tab.slug ? "auto" : null}
      mr={tabs.slice(-1)?.[0]?.slug === tab.slug ? "auto" : null}
      whiteSpace="nowrap"
      handleClick={onClick}
      disabled={tab.disabled}
      {...borderProps}
    >
      {tab.label}
    </Button>
  );
}

Tab.defaultProps = {
  borderRadiusTop: true,
  borderRadiusBottom: false,
  px: 40,
  py: 20
};
