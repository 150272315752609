import { forwardRef } from "react";

import Box from "components/Box";

import Center from "./Center";

const Input = forwardRef(
  (
    {
      bg,
      placeholder,
      height,
      rightIcon,
      px,
      py,
      borderRadius,
      onBlur,
      onFocus,
      value,
      onChange,
      wrapperProps,
      name,
      type,
      ...rest
    },
    ref
  ) => {
    return (
      <Box position="relative" {...wrapperProps}>
        <Box
          as="input"
          height={height}
          pl={px}
          pr={rightIcon ? `calc(${px * 2}px)` : px}
          py={py}
          ref={ref}
          borderRadius={borderRadius}
          bg={bg}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          {...rest}
        />
        {rightIcon && (
          <Box
            position="absolute"
            right={0}
            top={0}
            bottom={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={px * 2}
            height={height}
          >
            {rightIcon}
          </Box>
        )}
      </Box>
    );
  }
);

Input.defaultProps = {
  type: "text"
};

export default Input;
