import useOrganisationBasket from "hooks/organisations/useOrganisationBasket";

const { createContext, useContext } = require("react");

const OrganisationBasketContext = createContext();

export function OrganisationBasketProvider({ children }) {
  const api = useOrganisationBasket();
  return (
    <OrganisationBasketContext.Provider value={api}>{children}</OrganisationBasketContext.Provider>
  );
}

export function useOrganisationBasketContext() {
  return useContext(OrganisationBasketContext);
}
