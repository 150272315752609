import { useContext, useMemo } from "react";

import { useHistory, useParams, Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import { gql } from "@apollo/client";
import Box from "components/Box";
import Grid from "components/Grid";
import SupplementPage from "components/SupplementPage";
import { useChangeOverTimeContext } from "contexts/ChangeOverTime";
import ParamsContext from "contexts/ParamsContext";
import {
  TestProductModalContext,
  TestProductModalProvider
} from "contexts/TestProductModalContext";
import { TestProductOptionProvider } from "contexts/TestProductOptionContext";
import { theme } from "core/theme";
import {
  PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY,
  PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS
} from "graphql/shop";
import useFirstAvailableOrganisation from "hooks/organisations/useFirstAvailableOrganisation";
import useWindowSize from "hooks/use-window-size";
import Danger from "images/Danger";

import DataLoader from "../components/DataLoader";
import Page from "../components/Page";
import {
  CLIENT_MANAGEMENT_PLATFORM_URL_BASE,
  CLIENT_MANAGEMENT_USER_SUBSECTOR,
  ERROR_404_URL,
  getClientManagementTabUrl,
  getClientManagementUserSubsectorUrl,
  getClientManagementUserTestUrl,
  getTPOSubsectorUrl
} from "../core/urls";
import {
  CORE_USER_THEME_WITH_SECTORS_FIELDS,
  USER_SUBSECTOR_FIELDS
} from "../graphql/tpo/common/types";
import { USER_TEST_FIELDS_TRUNCATED } from "../graphql/tpo/results/types";
import Accordion from "./Accordion";
import { SymptomsList } from "./Accordions";
import { PanelBoxV2 } from "./Boxes";
import Center from "./Center";
import { CollapseableText } from "./CollapseableText";
import Currency from "./Currency";
import { ExpiredMarkersToggleSwitch } from "./ExpiredMarkersToggleSwitch";
import FoodPage, { FoodList } from "./FoodsPage";
import Group from "./Group";
import Menu, { Button as MenuButton } from "./Menu";
import Modal from "./Modal";
import NothingToShow from "./NothingToShow";
import Params from "./Params";
import RecommendationPage from "./RecommendationPage";
import {
  USER_SUBSECTORS_QUERY,
  PriorityRecommendations,
  RecommendationModalContent,
  UserTestRecommendationsPanel
} from "./Recommendations";
import Spacer from "./Spacer";
import Stack from "./Stack";
import Tab from "./Tab";
import TestStatuses from "./TestStatuses";
import UserDatapointPage from "./UserDatapoint";
import UserResultPage from "./UserResult";
import { CardListContext } from "./UserResults";
import {
  USER_SUBSECTOR_QUERY,
  UserSubsectorBody,
  UserSubsectorMenuItem,
  useCardList,
  useUserSubsectorsMenu
} from "./UserSubsector";
import { GET_DISPLAY_GROUPS, GenesTestContent, NonGenesTestContent } from "./UserTest";
import { UserSubsectorScores } from "./UserTheme";
import { ViewerContext } from "./Viewer";
import TestProductModal from "./partnerDashboard/practitioner/TestProductModal";
import StackedListingViewCard from "./shop/StackedListingViewCard";
import { getCarouselImages } from "./shop/TestProductDetailPage";
import TestRecommendationBadge from "./shop/TestRecommendationBadge";

const ALL_TABS = [
  {
    label: "tests",
    slug: "tests"
  },
  {
    label: "wellness scores",
    slug: "wellness"
  },
  {
    label: "self assessment",
    slug: "self_assessment"
  },
  {
    label: "recommendations",
    slug: "recommendations"
  },
  {
    label: "food list",
    slug: "food_list"
  }
];

const PATIENT_DETAILS = gql`
  query PatientDetails($userId: ID!) {
    user(userId: $userId) {
      id
      firstName
      lastName
    }
  }
`;

function GenesTest({ userTest }) {
  const { patientId } = useParams();

  return (
    <DataLoader
      query={GET_DISPLAY_GROUPS}
      variables={{
        userId: patientId,
        userTestId: userTest.id
      }}
      render={({ userTest: { resultDisplayGroups } }) => (
        <GenesTestContent
          clientId={patientId}
          userTest={userTest}
          resultDisplayGroups={resultDisplayGroups}
        />
      )}
    />
  );
}

function Tests({ userTests, userTest }) {
  const { patientId } = useParams();
  const history = useHistory();

  return (
    <>
      <PanelBoxV2
        maxWidth={1538}
        outer={{
          pt: [30, 30, 60],
          px: 20,
          pb: [40, 40, 80]
        }}
      >
        <Stack gap={40}>
          <PanelBoxV2 maxWidth={[464, 464, 464, 500]}>
            {userTests.length ? (
              <TestStatuses
                heading="Available tests"
                ordersInProgress={[]}
                testResults={userTests}
                handleSelect={selected => {
                  return history.push(getClientManagementUserTestUrl(patientId, selected.id));
                }}
                defaultOpen={false}
              />
            ) : (
              <TestStatuses heading="No tests" />
            )}
          </PanelBoxV2>
          {!!userTest && (
            <Center>
              <Box>
                <Box fontFamily="gilroyBold" fontSize={[24, 24, 44]} textAlign="center">
                  {userTest?.name}
                </Box>
                <Box textAlign="center">
                  <Box as="span" fontFamily="gilroyBold">
                    Sample taken:
                  </Box>
                  <Box as="span" fontFamily="gilroyMedium" fontSize={16} ml={1}>
                    {userTest?.sampleCollectedDateFormatted}
                  </Box>
                </Box>
              </Box>
            </Center>
          )}
        </Stack>
        <Box py={[15, 15, 20]} />
        {!!userTest && (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            {userTest.isGenesTest ? (
              <GenesTest userTest={userTest} />
            ) : (
              <NonGenesTestContent
                userTest={userTest}
                showFullExplanation={false}
                clientId={patientId}
              />
            )}
          </ViewerContext.Provider>
        )}
      </PanelBoxV2>
    </>
  );
}

const PATIENT_TESTS = gql`
  query PatientTests($userId: ID!, $userTestId: ID!) {
    userTests(userId: $userId) {
      ...UserTestFieldsTruncated
    }
    userTest(userId: $userId, userTestId: $userTestId) {
      ...UserTestFieldsTruncated
    }
  }
  ${USER_TEST_FIELDS_TRUNCATED}
`;

function NoTests() {
  return (
    <NothingToShow
      jumbotron={
        <>
          <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            No purchased tests
          </Box>
          <Box py={[2, 2, 20]} />
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
            Your patient currently has no purchased tests. Details will be available here once an
            order has been placed.
          </Box>
        </>
      }
    />
  );
}

function TestsTab() {
  const { patientId, id } = useParams();

  return (
    <DataLoader
      query={PATIENT_TESTS}
      variables={{
        userId: patientId,
        userTestId: id
      }}
      render={({ userTests, userTest }) =>
        userTest && userTests.length ? (
          <Tests userTests={userTests} userTest={userTest} />
        ) : (
          <Redirect to={ERROR_404_URL} />
        )
      }
    />
  );
}

const USER_THEME_QUERY = gql`
  query UserThemeQuery($themeSlug: String!, $userId: ID!) {
    userTheme(themeSlug: $themeSlug, userId: $userId) {
      ...CoreUserThemeWithSectorsFields
    }
    userSubsectors(themeSlug: $themeSlug, userId: $userId) {
      ...UserSubsectorFields
      userResultCount
      chartConfiguration {
        colours
        normalisedRange {
          value
        }
      }
    }
  }
  ${CORE_USER_THEME_WITH_SECTORS_FIELDS}
  ${USER_SUBSECTOR_FIELDS}
`;

function UserSubsectorMenu({ userSubsector: pageUserSubsector }) {
  const { userSubsectors, open, setOpen } = useUserSubsectorsMenu({
    themeSlug: "health"
  });
  const history = useHistory();
  const viewerCtx = useContext(ViewerContext);

  return (
    <Menu opened={open} onChange={setOpen} trigger="hover">
      <Menu.Target>
        <MenuButton
          opened={open}
          lineHeight="24px"
          paddingY="16px"
          paddingX="40px"
          maxWidth="100%"
          bg="dark"
          color="white"
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "100%"
            }}
          >
            {pageUserSubsector.name}
          </span>
        </MenuButton>
      </Menu.Target>
      {!!userSubsectors.length && (
        <Menu.Dropdown
          mt={2}
          py={2}
          zIndex={2}
          minWidth={310}
          maxWidth={310}
          height={500}
          maxHeight={500}
          overflowY="auto"
          bg="dark"
        >
          {userSubsectors.map(userSubsector => (
            <Menu.Item
              onClick={() =>
                history.push(
                  viewerCtx?.userId
                    ? getClientManagementUserSubsectorUrl(viewerCtx.userId, userSubsector.slug)
                    : getTPOSubsectorUrl("health", userSubsector.slug)
                )
              }
              key={userSubsector.id}
              selected={userSubsector.id === pageUserSubsector.id}
              py={16}
              selectedColor="rgba(255, 255, 255, 0.10)"
            >
              <UserSubsectorMenuItem userSubsector={userSubsector} color="white" />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      )}
    </Menu>
  );
}

function UserSubsector() {
  const { subsectorSlug } = useParams();
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={USER_SUBSECTOR_QUERY}
      variables={{ themeSlug: "health", subsectorSlug, userId: viewerContext?.userId }}
      render={({ userSubsector }) => (
        <UserSubsectorBody
          aboveMainChart={
            <>
              <Center>
                <UserSubsectorMenu userSubsector={userSubsector} />
              </Center>
              <Box py={[2, 2, 20]} />
            </>
          }
          userSubsector={userSubsector}
        />
      )}
    />
  );
}

function WellnessScoresTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <Switch>
      <Route path={CLIENT_MANAGEMENT_PLATFORM_URL_BASE} exact>
        <DataLoader
          query={USER_THEME_QUERY}
          variables={{
            userId: viewerContext?.userId,
            themeSlug: "health"
          }}
          render={({ userTheme, userSubsectors }) =>
            userSubsectors.length ? (
              <>
                <PanelBoxV2
                  maxWidth={760}
                  outer={{
                    pt: [40, 40, 60],
                    px: 20,
                    pb: [50, 50, 80],
                    bg: "white"
                  }}
                >
                  <PanelBoxV2
                    maxWidth={760}
                    outer={{
                      px: 20
                    }}
                  >
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                      Patient Health Scores
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <CollapseableText fontSize={[14, 14, 16]}>
                      {`In this sector, we are looking at how various structures of the body, such as,
                      cells, tissues, organs, and systems are interlinked.

                      A healthy body is maintained by good nutrition, optimal detoxification, regular exercise, balanced hormones, avoiding harmful habits, making informed and responsible decisions about health, and seeking medical assistance when necessary.`}
                    </CollapseableText>
                  </PanelBoxV2>
                </PanelBoxV2>
                <UserSubsectorScores userTheme={userTheme} userSubsectors={userSubsectors} />
              </>
            ) : (
              <NothingToShow
                jumbotron={
                  <>
                    <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                      No Results available
                    </Box>
                    <Box py={[2, 2, 20]} />
                    <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                      Your patients wellness results will become available once they have taken a
                      functional test or completed a symptom questionnaire.
                    </Box>
                  </>
                }
              />
            )
          }
        />
      </Route>
      <Route path={CLIENT_MANAGEMENT_USER_SUBSECTOR} component={UserSubsector} />
    </Switch>
  );
}

const GET_PARTNER_QUESTIONNAIRE_RESULTS = gql`
  query GET_PARTNER_QUESTIONNAIRE_RESULTS($userId: ID!) {
    userSubmission(userId: $userId) {
      id
      report {
        hasAnswers
        sections {
          presentationSection
          userQuestions {
            id
            question {
              id
              text
            }
            answerScore
            answerText
          }
        }
        outOfRange {
          id
          question {
            id
            text
          }
          answerScore
          answerText
        }
      }
    }
  }
`;

function SelfAssessment({ sections, outOfRange }) {
  return (
    <>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: 20
        }}
      >
        <PanelBoxV2
          maxWidth={760}
          outer={{
            px: 20
          }}
        >
          <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
            Self assessment
          </Box>
          <Box py={[2, 2, 20]} />
          <CollapseableText fontSize={[14, 14, 16]}>
            Find below all responses to the symptoms questionnaire which form part of the wellness
            score. Please encourage your patient for a regular update every 3-6 months to keep it up
            to date.
          </CollapseableText>
          {outOfRange.length && (
            <>
              <Spacer py={[2, 2, 20]} />
              <Center>
                <Group gap={10} alignItems="center">
                  <Danger size={24} />
                  <Box color="error" fontSize={16} fontFamily="gilroyBold">
                    Includes expired answers
                  </Box>
                </Group>
              </Center>
            </>
          )}
        </PanelBoxV2>
        <Box py={[2, 2, 20]} />
        <Accordion
          variant="separated"
          chevronVariant="circle"
          multiple
          gap={[10, 10, 20]}
          size={20}
        >
          {outOfRange.length && (
            <Accordion.Item value="outOfRange">
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box fontFamily="gilroyBold" fontSize={[16, 16, 18]} lineHeight="130%">
                  Summary - Out of range
                </Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={outOfRange} />
              </Accordion.Panel>
            </Accordion.Item>
          )}
          {sections?.map(section => (
            <Accordion.Item value={section.presentationSection} key={section.presentationSection}>
              <Accordion.Control px={[15, 15, 20]} py={[2, 2, 15]}>
                <Box
                  fontFamily="gilroyBold"
                  fontSize={[16, 16, 18]}
                  lineHeight="130%"
                >{`Section ${section.presentationSection}`}</Box>
              </Accordion.Control>
              <Accordion.Panel px={[20, 20, 40]}>
                <SymptomsList questions={section.userQuestions} />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </PanelBoxV2>
    </>
  );
}

function SelfAssessmentTab() {
  const viewerContext = useContext(ViewerContext);

  return (
    <DataLoader
      query={GET_PARTNER_QUESTIONNAIRE_RESULTS}
      variables={{
        userId: viewerContext.userId
      }}
      render={({ userSubmission }) =>
        userSubmission?.report?.hasAnswers ? (
          <SelfAssessment
            sections={userSubmission.report.sections}
            outOfRange={userSubmission.report.outOfRange}
          />
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  Self assessment
                </Box>
                <Box py={[2, 2, 20]} />
                <CollapseableText fontSize={[14, 14, 16]}>
                  The following answers from the symptom questionnaire are used in th wellness
                  scoring system. Encourage your patient to update this questionnaire every 3-6
                  months.
                </CollapseableText>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No responses available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

function UserTestRecommendationsListForPractitioners({ userTestRecommendations }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;

  return (
    <TestProductModalContext.Consumer>
      {({ testProduct, selectTestProductId }) => {
        let headerBg;
        if (testProduct) {
          const carouselImages = getCarouselImages(testProduct);
          headerBg = carouselImages.length ? "white" : "haze";
        }

        return (
          <>
            <Grid>
              {userTestRecommendations.slice(0, 5).map((userTestRecommendation, index) => {
                const tradeFullPrice = userTestRecommendation.product.tradeFullPrice;
                const tradeCurrentPrice = userTestRecommendation.product.tradeCurrentPrice;
                const retailFullPrice =
                  userTestRecommendation.product.retailFullPriceForPractitionerShop;

                return (
                  <StackedListingViewCard
                    stacked={isMobile}
                    badge={<TestRecommendationBadge bg="white" color="dark" rank={index + 1} />}
                    matchedSymptoms={userTestRecommendation.symptoms
                      ?.slice(0, 5)
                      .map(symptom => symptom.name)}
                    key={userTestRecommendation.id}
                    name={userTestRecommendation.product.name}
                    priceElement={
                      <Stack
                        ml={[null, "auto"]}
                        justifyContent="flex-start"
                        alignItems="flex-end"
                        gridColumnStart="2"
                        gridColumnEnd="3"
                      >
                        <Group gap={10}>
                          {tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice && (
                            <Currency
                              fontSize={[24, 44]}
                              value={tradeFullPrice}
                              decimalFontSize={[16, 28]}
                              color="dark"
                              strikethrough
                              data-testid="testProductListCard:tradeFullPrice"
                            />
                          )}
                          <Currency
                            fontSize={[24, 44]}
                            value={tradeCurrentPrice}
                            decimalFontSize={[16, 28]}
                            data-testid="testProductListCard:tradeCurrentPrice"
                            color={
                              tradeFullPrice !== undefined && tradeFullPrice > tradeCurrentPrice
                                ? "red"
                                : "dark"
                            }
                          />
                        </Group>
                        <Currency
                          prefix="RRP"
                          color="midGrey"
                          fontSize={18}
                          value={retailFullPrice}
                          data-testid="testProductListCard:retailFullPrice"
                        />
                      </Stack>
                    }
                    description={userTestRecommendation.product.content.description}
                    sampleTypes={userTestRecommendation.product.sampleTypes}
                    numOfBiomarkersTested={
                      userTestRecommendation.product.content.numOfBiomarkersTested
                    }
                    slug={userTestRecommendation.product.slug}
                    image={
                      userTestRecommendation.product.content.shot1Purple?.map(image => ({
                        width: image.width,
                        image: image.resizeUrl
                      })) || []
                    }
                    categories={userTestRecommendation.product.content.categories}
                    productAudience={userTestRecommendation.product.productAudience}
                    imageBackgroundColor={theme.colors.purple}
                    testType={userTestRecommendation.product.content.testType}
                    onClick={() => selectTestProductId(userTestRecommendation.product.id)}
                  />
                );
              })}
            </Grid>
            <TestProductModal headerBg={headerBg} />
          </>
        );
      }}
    </TestProductModalContext.Consumer>
  );
}

function RecommendationsTab() {
  const viewerContext = useContext(ViewerContext);

  const { viewCard, closeCard, cardOpen } = useCardList();
  const { includeExpiredMarkers } = useChangeOverTimeContext();

  const cardListApi = useMemo(
    () => ({
      viewCard,
      closeCard
    }),
    [viewCard, closeCard]
  );

  return (
    <DataLoader
      query={USER_SUBSECTORS_QUERY}
      variables={{
        dimensions: [
          {
            width: 990
          },
          {
            width: 1000
          }
        ],
        excludeEmptyRecommendations: true,
        includeThingsToDoCount: true,
        includeThingsToAvoidCount: true,
        includeThingsToTakeCount: true,
        includePeopleToSeeCount: true,
        userId: viewerContext?.userId,
        suppressed: false,
        fresh: !includeExpiredMarkers
      }}
      render={({ userSubsectors, userTestRecommendations }) =>
        userSubsectors?.length ? (
          <>
            <CardListContext.Provider value={cardListApi}>
              <TestProductOptionProvider
                testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
                testProductOptionFragmentName="PractitionerTestProductOptionFields"
                testProductOptionFragmentType="TestProductOptionType"
              >
                <TestProductModalProvider
                  testProductQuery={PRACTITIONER_TEST_PRODUCT_DETAIL_QUERY}
                  testProductFieldName="practitionerTestProduct"
                  testProductOptionFragment={PRACTITIONER_TEST_PRODUCT_OPTION_FIELDS}
                  testProductOptionFragmentName="practitionerTestProductOption"
                  testProductOptionFragmentType="PractitionerTestProductOptionType"
                >
                  <PriorityRecommendations
                    header="Patient Prioritised Recommendation List"
                    userSubsectors={userSubsectors}
                    userTestRecommendations={userTestRecommendations}
                    bodyCopy=""
                    userTestRecommendationsElement={
                      userTestRecommendations.length ? (
                        <UserTestRecommendationsPanel
                          listElement={
                            <UserTestRecommendationsListForPractitioners
                              userTestRecommendations={userTestRecommendations}
                            />
                          }
                        />
                      ) : null
                    }
                  />
                </TestProductModalProvider>
              </TestProductOptionProvider>
            </CardListContext.Provider>
            <Modal
              mode="centered"
              bg={cardOpen?.bg}
              close={closeCard}
              show={!!cardOpen}
              maxWidth={1020}
              closeButton
              headerProps={{
                pr: 20,
                pt: 20,
                pb: [40, 40, 20]
              }}
            >
              {cardOpen?.item?.id && (
                <RecommendationModalContent recommendationId={cardOpen.item.id} />
              )}
            </Modal>
          </>
        ) : (
          <NothingToShow
            header={
              <PanelBoxV2
                maxWidth={760}
                outer={{
                  px: 20
                }}
              >
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  {PriorityRecommendations.defaultProps.header}
                </Box>
              </PanelBoxV2>
            }
            jumbotron={
              <>
                <Box as="h2" fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
                  No recommendations available
                </Box>
                <Box py={[2, 2, 20]} />
                <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
                  Looks like your patient is yet to complete their symptom questionnaire.
                </Box>
              </>
            }
          />
        )
      }
    />
  );
}

const GET_FIRST_USER_TEST = gql`
  query GetFirstUserTest($userId: ID!) {
    userTests(userId: $userId) {
      id
    }
  }
`;

function Template({ children, selectedTab }) {
  const { patientId } = useParams();
  const history = useHistory();
  return (
    <DataLoader
      query={PATIENT_DETAILS}
      variables={{
        userId: patientId
      }}
      render={({ user }) => {
        return user ? (
          <ViewerContext.Provider
            value={{
              userId: patientId
            }}
          >
            <Page bg="haze" includeFooter addNavBarHeight={false}>
              <Box bg="partners">
                <ExpiredMarkersToggleSwitch />
                <PanelBoxV2
                  maxWidth={1020}
                  outer={{
                    px: 20
                  }}
                >
                  <Box py={[20, 20, 30]} />
                  <Box
                    color="dark"
                    fontSize={[24, 34, 34, 44]}
                    lineHeight="115%"
                    fontFamily="gilroyBold"
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Box>
                  <Box py={[2, 2, 15]} />
                </PanelBoxV2>
              </Box>
              <PanelBoxV2
                outer={{
                  bg: "partners",
                  borderWidth: 1,
                  borderBottomStyle: "solid",
                  borderColor: "haze"
                }}
                inner={{
                  display: "flex",
                  overflowX: "auto",
                  pl: 20
                }}
              >
                {ALL_TABS.map(tab => (
                  <Tab
                    tab={tab}
                    tabs={ALL_TABS}
                    selectedTab={selectedTab}
                    key={tab.label}
                    onClick={() => {
                      history.push(getClientManagementTabUrl(patientId, tab.slug));
                    }}
                  />
                ))}
              </PanelBoxV2>
              {children}
            </Page>
          </ViewerContext.Provider>
        ) : (
          <Redirect to={ERROR_404_URL} />
        );
      }}
    />
  );
}

function ClientManagementPlatform() {
  const match = useRouteMatch();
  const { patientId } = useParams();

  return (
    <Switch>
      <Route path={match.path} exact>
        <Redirect to={getClientManagementTabUrl(patientId, "tests")} />
      </Route>
      <Route path={`${match.path}/tests`} exact>
        <DataLoader
          query={GET_FIRST_USER_TEST}
          variables={{
            userId: patientId
          }}
          render={({ userTests }) =>
            userTests.length ? (
              <Redirect to={getClientManagementUserTestUrl(patientId, userTests[0].id)} />
            ) : (
              <NoTests />
            )
          }
        />
      </Route>
      <Route path={`${match.path}/tests/:id`} exact>
        <Template selectedTab="tests">
          <TestsTab />
        </Template>
      </Route>
      <Route path={`${match.path}/wellness`} exact>
        <Template selectedTab="wellness">
          <WellnessScoresTab />
        </Template>
      </Route>
      <Route path={`${match.path}/self_assessment`} exact>
        <Template selectedTab="self_assessment">
          <SelfAssessmentTab />
        </Template>
      </Route>
      <Route path={`${match.path}/recommendations`} exact>
        <Template selectedTab="recommendations">
          <RecommendationsTab />
        </Template>
      </Route>
      <Route path={`${match.path}/food_list`} exact>
        <Template selectedTab="food_list">
          <FoodList />
        </Template>
      </Route>
    </Switch>
  );
}

export default function ClientManagementPlatformRouting() {
  const match = useRouteMatch();

  const { loadingElement } = useFirstAvailableOrganisation();

  if (loadingElement) return loadingElement;

  return (
    <Switch>
      <Route path={`${match.path}/result/:userResultId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserResultPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/biomarker/:userDatapointId`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <UserDatapointPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/supplements/:slug`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <SupplementPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/food-list`} exact>
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <FoodPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}/recommendations/:recommendationId`}>
        {/* Not sure it's possible through the UI to get here but this is in case any old links
        exist pointing to the old /partner ... urls.  For these we'd redirect to here */}
        <Params>
          <ParamsContext.Consumer>
            {({ patientId: userId }) => (
              <ViewerContext.Provider
                value={{
                  userId
                }}
              >
                <RecommendationPage />
              </ViewerContext.Provider>
            )}
          </ParamsContext.Consumer>
        </Params>
      </Route>
      <Route path={`${match.path}`} component={ClientManagementPlatform} />
    </Switch>
  );
}
