import { Fragment, useMemo } from "react";

import { gql } from "@apollo/client";
import Box from "components/Box";
import DataLoader from "components/DataLoader";
import Divider from "components/Divider";
import Page from "components/Page";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { useDataLoader } from "hooks/useDataLoader";
import { PanelBoxV2 } from "tpo/Boxes";
import Currency from "tpo/Currency";
import Group from "tpo/Group";
import { List, ListContext } from "tpo/List";
import SimpleTable from "tpo/SimpleTable";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import Table, { useTableControls } from "tpo/Table";

const SORT_OPTIONS = [
  {
    label: "Date order (Newest - Oldest)",
    value: "-created"
  },
  {
    label: "Date order (Oldest - Newest)",
    value: "created"
  }
];

const COMMISSIONS_QUERY = gql`
  query CommissionsQuery($page: Int, $orderBy: String, $search: String) {
    partnerUserProfile {
      id
      commissionsPagination(page: $page, orderBy: $orderBy, search: $search) {
        items {
          id
          createdDateFormatted
          user {
            id
            firstName
            lastName
            email
          }
          commissionPayoutPercentage
          commission
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

function CommissionsTable() {
  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { data, loading } = useDataLoader({
    query: COMMISSIONS_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch
    }
  });

  const columns = useMemo(
    () => [
      {
        id: "client",
        label: "Client",
        accessor: ({ user }) => {
          return user ? (
            <Box display="flex" flexDirection="column" gap={10}>
              {!!(user?.firstName && user?.lastName) && (
                <Box
                  fontFamily="gilroyBold"
                  fontSize={16}
                >{`${user?.firstName}, ${user?.lastName}`}</Box>
              )}
              {!!user?.email && <Box fontSize={16}>{user?.email}</Box>}
            </Box>
          ) : null;
        }
      },
      {
        id: "orderDate",
        accessor: "createdDateFormatted",
        label: "Order Date"
      },
      {
        id: "id",
        accessor: "id",
        label: "Order No"
      },
      {
        id: "commission_payout_percentage",
        accessor: ({ commissionPayoutPercentage }) => {
          return (
            <Box as="span">
              <Box as="span">{commissionPayoutPercentage}</Box>
              <Box as="span">%</Box>
            </Box>
          );
        },
        label: "Discount"
      },
      {
        id: "commission",
        accessor: ({ commission }) => {
          return <Currency value={commission} />;
        },
        label: "Commission"
      }
    ],
    []
  );

  const items = data?.partnerUserProfile?.commissionsPagination?.items;
  const pageInfo = data?.partnerUserProfile?.commissionsPagination?.pageInfo;

  return (
    <PanelBoxV2 maxWidth={1200}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
          Commissions
        </Box>
        <Spacer py={[2, 2, 20]} />
        <SimpleTable
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          pageInfo={pageInfo}
          setPage={setPage}
          sortingOptions={SORT_OPTIONS}
          table={
            <>
              <Box display={["none", "none", "block"]}>
                <Table columns={columns} loading={loading} rows={items} />
              </Box>
              <List items={items} loading={loading} display={["block", "block", "none"]}>
                <ListContext.Consumer>
                  {items =>
                    items?.map(
                      ({
                        user,
                        id,
                        createdDateFormatted,
                        commissionPayoutPercentage,
                        commission
                      }) => (
                        <Fragment key={id}>
                          <Box py={2}>
                            <Box py={15}>
                              <Stack gap={20}>
                                <Box display="flex" flexDirection="column">
                                  {!!(user?.firstName && user?.lastName) && (
                                    <Box
                                      fontFamily="gilroyBold"
                                      fontSize={16}
                                    >{`${user?.firstName}, ${user?.lastName}`}</Box>
                                  )}
                                  {!!user?.email && <Box fontSize={16}>{user?.email}</Box>}
                                </Box>
                                <Box>
                                  <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                    Order Date
                                  </Box>
                                  <Box>{createdDateFormatted}</Box>
                                </Box>
                                <Box>
                                  <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                    Order No
                                  </Box>
                                  <Box>{id}</Box>
                                </Box>
                                <Box>
                                  <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                    Discount
                                  </Box>
                                  <Box>
                                    <Box as="span">
                                      <Box as="span">{commissionPayoutPercentage}</Box>
                                      <Box as="span">%</Box>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box>
                                  <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                    Commission
                                  </Box>
                                  <Currency value={commission} />
                                </Box>
                              </Stack>
                            </Box>
                          </Box>
                          <Divider />
                        </Fragment>
                      )
                    )
                  }
                </ListContext.Consumer>
              </List>
            </>
          }
        />
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

const PAYOUT_QUERY = gql`
  query PayoutQuery($page: Int, $orderBy: String, $search: String) {
    partnerUserProfile {
      id
      payoutPagination(page: $page, orderBy: $orderBy, search: $search) {
        items {
          id
          createdDateFormatted
          status
          amount
        }
        pageInfo {
          ...PageInfoFields
        }
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

function PayoutsTable() {
  const {
    page,
    sort,
    debouncedSearch,
    search,
    setSearch,
    setPage,
    setSort,
    sortMenuOpen,
    setSortMenuOpen
  } = useTableControls({
    sortOptions: SORT_OPTIONS
  });

  const { data, loading } = useDataLoader({
    query: PAYOUT_QUERY,
    variables: {
      page,
      orderBy: sort,
      search: debouncedSearch
    }
  });

  const columns = useMemo(
    () => [
      {
        id: "id",
        accessor: "id",
        width: "40%"
      },
      {
        id: "created",
        accessor: "createdDateFormatted",
        width: "20%"
      },
      {
        id: "status",
        accessor: "status",
        width: "20%"
      },
      {
        id: "amount",
        accessor: ({ amount }) => {
          return <Currency value={amount} />;
        },
        width: "40%",
        align: "right"
      }
    ],
    []
  );

  const items = data?.partnerUserProfile?.payoutPagination?.items;
  const pageInfo = data?.partnerUserProfile?.payoutPagination?.pageInfo;

  return (
    <PanelBoxV2 maxWidth={1200}>
      <PanelBoxV2
        maxWidth={1020}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          bg: "white",
          px: 20,
          borderRadius: 5
        }}
      >
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
          Invoices
        </Box>
        <Spacer py={[2, 2, 20]} />
        <SimpleTable
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          sortMenuOpen={sortMenuOpen}
          setSortMenuOpen={setSortMenuOpen}
          pageInfo={pageInfo}
          setPage={setPage}
          sortingOptions={SORT_OPTIONS}
          table={
            <>
              <Box display={["none", "none", "block"]}>
                <Table columns={columns} loading={loading} rows={items} />
              </Box>
              <List display={["block", "block", "none"]} items={items} loading={loading}>
                <ListContext.Consumer>
                  {items =>
                    items?.map(item => (
                      <Fragment key={item.id}>
                        <Box py={2}>
                          <Box py={15}>
                            <Stack gap={20}>
                              <Group justifyContent="space-between">
                                <Box fontFamily="gilroyBold" fontSize={16}>
                                  {item.id}
                                </Box>
                                <Currency value={item.amount} />
                              </Group>
                              <Box>
                                <Box fontFamily="gilroyBold" fontSize={16} lineHeight="150%">
                                  Order Date
                                </Box>
                                <Box>{item.createdDateFormatted}</Box>
                              </Box>
                              <Box>{item.status}</Box>
                            </Stack>
                          </Box>
                        </Box>
                        <Divider />
                      </Fragment>
                    ))
                  }
                </ListContext.Consumer>
              </List>
            </>
          }
        />
      </PanelBoxV2>
    </PanelBoxV2>
  );
}

const BALANCE_QUERY = gql`
  query BalanceQuery {
    partnerUserProfile {
      id
      balance
      user {
        id
        hashId
      }
    }
  }
`;

function Balance() {
  return (
    <PanelBoxV2
      maxWidth={1200}
      inner={{
        bg: "partners",
        px: [20, 20, 40],
        pt: [30, 30, 60],
        pb: [50, 50, 80],
        borderRadius: 5
      }}
    >
      <DataLoader
        query={BALANCE_QUERY}
        fetchPolicy="no-cache"
        nextFetchPolicy="no-cache"
        render={({
          partnerUserProfile: {
            balance,
            user: { hashId }
          }
        }) => (
          <Box pt={[20, 20, 40]} pb={[30, 30, 60]}>
            <Group justifyContent="space-between">
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
                Your balance
              </Box>
              <Box textAlign="right">
                <Currency fontSize={48} value={balance} />
                <Box fontFamily="gilroyMedium" fontSize={18}>
                  Current balance owed
                </Box>
              </Box>
            </Group>
            <Spacer py={[2, 2, 20]} />
            <Divider color="black" />
            <Spacer py={[2, 2, 20]} />
            <Box fontFamily="gilroyMedium" fontSize={16}>
              To claim your commission fees please send an invoice to finance@omnos.me including
              your name, payment details and your Omnos partner number {hashId}
              {/* TODO - confirm we have using the right ref here for the Omnos partner number */}
            </Box>
          </Box>
        )}
      />
    </PanelBoxV2>
  );
}

export default function Commissions({ tabs }) {
  return (
    <Page bg="haze" includeFooter addNavBarHeight={false}>
      {tabs}
      <PanelBoxV2
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20],
          bg: "haze"
        }}
      >
        <CommissionsTable />
        <Spacer py={[2, 2, 20]} />
        <PayoutsTable />
        <Spacer py={[2, 2, 20]} />
        <Balance />
      </PanelBoxV2>
    </Page>
  );
}
