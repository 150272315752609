import React from "react";

import { theme } from "../core/theme";
import Box from "./Box";
import Image from "./Image";

function BackgroundImage({
  children,
  backgroundImage,
  backgroundColor,
  alt,
  width,
  height,
  minHeight,
  ...props
}) {
  return (
    <Box
      backgroundColor={backgroundColor || theme.colors.slate}
      position="relative"
      data-component-name="BackgroundImage"
      width={width}
      height={height}
      minHeight={minHeight}
      {...props}
    >
      <Image
        src={backgroundImage}
        alt={alt}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover"
        }}
      />
      <Box position="relative">{children}</Box>
    </Box>
  );
}

export default BackgroundImage;
