import React, { useRef, useState } from "react";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

import { PARTNERS_DASHBOARD_URL, PARTNERS_EXTERNAL_URL } from "core/urls";
import AffiliateSignupRedirect from "tpo/partnerDashboard/affiliate/SignupRedirect";
import PractitionerApplication from "tpo/partnerDashboard/practitioner/Application";
import ApplicationSubmitted from "tpo/partnerDashboard/practitioner/ApplicationSubmitted";

import { AFFILIATE } from "../core/constants";
import { theme } from "../core/theme";
import { PARTNER_USER_PROFILE_QUERY } from "../graphql/accounts";
import useDocTitle from "../hooks/use-doc-title";
import headerImage from "../images/partners-header.jpg";
import { useAuthContext } from "./AuthProvider";
import BackgroundImage from "./BackgroundImage";
import Box from "./Box";
import { SpacedPanelBox } from "./Boxes";
import CenteredHeader from "./CenteredHeader";
import DataLoader from "./DataLoader";
import { HeadingExtraExtraLarge, HeadingLarge } from "./Headings";
import Page from "./Page";
import PartnerApplicationForm from "./PartnerApplicationForm";
import SwitcherHeader from "./SwitcherHeader";
import Text from "./Text";

export function PartnersDashboardHeader(props) {
  return <SwitcherHeader backgroundColor="partners" {...props} />;
}

export function PartnersCenteredHeader(props) {
  return <CenteredHeader color="dark" backgroundColor="partners" {...props} />;
}

function PartnersImageHeader() {
  return (
    <BackgroundImage backgroundColor={theme.colors.purple} backgroundImage={headerImage}>
      <Box px={20} pt={[160, 160, 240, 320]} pb={[40, 80, 120, 160]}>
        <Box maxWidth={1200} mx="auto">
          <HeadingExtraExtraLarge color="white" as={"h1"}>
            Partners
          </HeadingExtraExtraLarge>
        </Box>
      </Box>
    </BackgroundImage>
  );
}

function PartnersPageBase({ header, children }) {
  return (
    <Page backgroundColor="haze" header={header}>
      <Box pb={theme.spacing.large}>{children}</Box>
    </Page>
  );
}

function PartnersSignup({ handleSubmitted, initialTier }) {
  const tier = initialTier ? initialTier : AFFILIATE;
  const scrollToRef = useRef(null);
  return (
    <PartnersPageBase header={<PartnersImageHeader />}>
      <div ref={scrollToRef}>
        <SpacedPanelBox>
          <Box maxWidth={1000} mx="auto">
            <HeadingLarge pb level="h2">
              {tier} partner programme
            </HeadingLarge>
            {tier === AFFILIATE ? (
              <Text>
                Complete the following information to become an Omnos affiliate and start gaining
                benefits
              </Text>
            ) : (
              <Text>
                Complete the application form below to apply to be an Omnos Practitioner and start
                gaining benefits. Once your application is recieved and reviewed you will get a
                breakdown of how our practitioner programme works. Alternatively please get in touch
                with partners@omnos.me
              </Text>
            )}
          </Box>
          <Box pt={[0, 20, 40, 40]}>
            <PartnerApplicationForm tier={tier} handleSubmitted={handleSubmitted} />
          </Box>
        </SpacedPanelBox>
      </div>
    </PartnersPageBase>
  );
}

function PartnersPage({ tier }) {
  useDocTitle("Partners");
  const { user } = useAuthContext();
  const [formSubmitted, setFormSubmitted] = useState(false);
  if (formSubmitted) {
    return <ApplicationSubmitted />;
  }
  if (user) {
    return (
      <DataLoader
        query={PARTNER_USER_PROFILE_QUERY}
        fetchPolicy="no-cache"
        nextFetchPolicy="no-cache"
        render={({ partnerUserProfile }) => {
          if (partnerUserProfile === null) {
            if (!tier) {
              window.location.href = PARTNERS_EXTERNAL_URL;
              return null;
            } else {
              return tier === AFFILIATE ? (
                <AffiliateSignupRedirect />
              ) : (
                <PractitionerApplication setFormSubmitted={setFormSubmitted} />
              );
            }
          }
          if (!partnerUserProfile.approved) {
            return <ApplicationSubmitted />;
          }
          return <Redirect to={PARTNERS_DASHBOARD_URL} />;
        }}
      />
    );
  }
  return tier === AFFILIATE ? (
    <AffiliateSignupRedirect />
  ) : (
    <PractitionerApplication setFormSubmitted={setFormSubmitted} />
  );
}

export default PartnersPage;
