import { useContext, useRef } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import PartnerContext from "contexts/PartnerContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import ControlledCheckboxGroup from "tpo/ControlledCheckboxGroup";
import ControlledFormField from "tpo/ControlledFormField";
import EditFormButtons from "tpo/EditFormButtons";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import FormControl from "tpo/FormControl";
import Stack from "tpo/Stack";

import { PROFILE_FORM_FIELDS } from "../common/graphql/types";

function initializeAffiliateProfileForm(partnerUserProfile) {
  return {
    id: partnerUserProfile.pk,
    description: partnerUserProfile.description.split(",").filter(str => str),
    descriptionOther: partnerUserProfile.descriptionOther,
    instagram: partnerUserProfile.instagram,
    linkedin: partnerUserProfile.linkedin,
    youtube: partnerUserProfile.youtube,
    websiteBlog: partnerUserProfile.websiteBlog
  };
}

const EDIT_AFFILIATE_PROFILE_MUTATION = gql`
  mutation EditPractitionerProfile($input: PartnerAffiliateProfileMutationInput!) {
    partnerAffiliateProfileMutation(input: $input) {
      partnerUserProfile {
        ...ProfileFormFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${PROFILE_FORM_FIELDS}
`;

export default function AffiliateProfileForm() {
  const partnerUserProfile = useContext(PartnerContext);

  const submitButtonRef = useRef();

  const { id, methods, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    defaultValues: initializeAffiliateProfileForm(partnerUserProfile),
    mutation: EDIT_AFFILIATE_PROFILE_MUTATION,
    mutationName: "partnerAffiliateProfileMutation",
    transformer: input => ({
      ...input,
      description: input.description.join(",")
    }),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(
        initializeAffiliateProfileForm(data.partnerAffiliateProfileMutation.partnerUserProfile)
      );
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  if (!id) {
    // this can't happen unless the field is accidentally
    // removed from the query
    // if id was missing however it would try to create a new instance
    // server side instead of editing the existing for this ID
    return null;
  }

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <PanelBoxV2
        maxWidth={1532}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20],
          bg: "white"
        }}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PanelBoxV2 maxWidth={760} stacked gap={40}>
            <FormControl
              as="fieldset"
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                  What best describes you?
                  <Asterisk />
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Select as many as you like
                </Box>
              }
              fields={
                <>
                  <ControlledCheckboxGroup
                    Container={Stack}
                    containerProps={{
                      gap: 20
                    }}
                    name="description"
                    values={[
                      {
                        label: "Influencer",
                        value: "Influencer"
                      },
                      {
                        label: "Personal Trainer",
                        value: "Personal Trainer"
                      },
                      {
                        label: "Ambassador",
                        value: "Ambassador"
                      },
                      {
                        label: "Blogger",
                        value: "Blogger"
                      },
                      {
                        label: "Retail site",
                        value: "Retail site"
                      }
                    ]}
                  />

                  <ControlledFormField
                    name="descriptionOther"
                    label="Other (please give details)"
                    Component={FloatingLabelInput}
                  />
                </>
              }
            />
            <FormControl
              label={
                <Box fontFamily="gilroyBold" fontSize={18} lineHeight="26px">
                  Social media
                </Box>
              }
              helpText={
                <Box fontFamily="gilroyMedium" fontSize={16}>
                  Please enter at least one of your social media links.
                </Box>
              }
            >
              <Stack gap={20}>
                <ControlledFormField
                  name="instagram"
                  label="Instagram"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="linkedin"
                  label="Linkedin"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="youtube"
                  label="Youtube"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="websiteBlog"
                  label="Website / Blog"
                  Component={FloatingLabelInput}
                />
              </Stack>
            </FormControl>
            <Box>
              <Center gap={20}>
                <EditFormButtons />
              </Center>
              {nonFieldError && (
                <Center color="red" py={20} mt={20}>
                  {nonFieldError}
                </Center>
              )}
            </Box>
          </PanelBoxV2>
        </form>
      </PanelBoxV2>
    </FormProvider>
  );
}
