import Menu, { Button } from "./Menu";

export default function SortMenu({ open, setOpen, options, value, setValue, styles, placeholder }) {
  return (
    <Menu opened={open} onChange={setOpen} trigger="hover">
      <Menu.Target>
        <Button open={open} {...(styles?.button || {})}>
          {placeholder}
        </Button>
      </Menu.Target>
      <Menu.Dropdown mt={2} py={2} zIndex={2} minWidth={200}>
        {options.map(sortOption => (
          <Menu.Item
            onClick={() => setValue(sortOption.value)}
            selected={sortOption.value === value}
            key={sortOption.value}
            fontSize={14}
            lineHeight={1.7}
            py={16}
          >
            {sortOption.label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
