import { OUT_OF_STOCK } from "core/constants";
import ChevronComponent from "tpo/Chevron";
import ButtonV2 from "v2/Buttons";

export default function AddToBasketButton({
  practitionerSupportRequired,
  hasClinics,
  onFindNearestClinic,
  onAddToBasket,
  status
}) {
  let text = practitionerSupportRequired
    ? "practitioner only"
    : hasClinics
    ? "find nearest clinic"
    : "add to basket";
  if (status === OUT_OF_STOCK) text = "Out of stock";

  return (
    <ButtonV2
      color={
        practitionerSupportRequired ? "midGrey" : status === OUT_OF_STOCK ? "midGrey" : "green"
      }
      rightIcon={<ChevronComponent />}
      disabled={practitionerSupportRequired || status === OUT_OF_STOCK}
      onClick={() => {
        if (practitionerSupportRequired || status === OUT_OF_STOCK) return;
        if (hasClinics && onFindNearestClinic) {
          onFindNearestClinic();
        } else if (onAddToBasket) {
          onAddToBasket();
        }
      }}
    >
      {text}
    </ButtonV2>
  );
}
