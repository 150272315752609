// Tip types
export const DO_TIP = "DO_TIP";
export const DONT_TIP = "DONT_TIP";

// Sectors
export const GENETICS = "Genetics";
export const HEALTH = "Health";
export const NUTRITION = "Nutrition";
export const STRESS = "Stress";
export const FITNESS = "Fitness";
export const LIFESTYLE = "Lifestyle";
export const INITIAL = "Initial";
export const FOOD = "Food";

export const SUPPLEMENTS = "Supplements";

// Product statuses
export const AVAILABLE = "Available";
export const COMING_SOON = "Coming soon";
export const OUT_OF_STOCK = "Out of stock";

// Product types
export const TEST = "Test";
export const SUPPLEMENT = "Supplement";

// Recommendations
export const THINGS_TO_DO = "Things to do";
export const THINGS_TO_AVOID = "Things to avoid";
export const THINGS_TO_TAKE = "Things to take";
export const PEOPLE_TO_SEE = "People to see";

// Filters
export const ALL = "All";
export const RECOMMENDED = "Recommended";
export const OUTSIDE_RANGE = "Outside range";

// Partner tiers
export const AFFILIATE = "Affiliate";
export const PRACTITIONER = "Practitioner";
export const EXPERT = "Expert";

// Interpretations
export const VERY_LOW = "very_low";
export const LOW = "low";
export const NORMAL = "normal";
export const HIGH = "high";
export const VERY_HIGH = "very_high";
export const ABSENT = "absent";
export const DETECTED = "detected";
export const INSUFFICIENT = "insufficient";
export const SUFFICIENT = "sufficient";
export const IMBALANCED = "imbalanced";
export const BALANCED = "balanced";

export const EXCLUDE_RESULTS = ["mushroom-sensitivity-risk"];

export const MODAL_OVERLAY_Z_INDEX = 1000;
export const FLAG_Z_INDEX = 1;
export const MAIN_MENU_Z_INDEX = MODAL_OVERLAY_Z_INDEX + FLAG_Z_INDEX + 1;
export const NAV_Z_INDEX = MAIN_MENU_Z_INDEX + 1;
export const MODAL_Z_INDEX = NAV_Z_INDEX + 1;

export const EXPIRED_MARKERS = "Expired markers";
