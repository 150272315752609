import React, { useState } from "react";

import PropTypes from "prop-types";

function Image({ src, className, alt, style, unloadedHeight }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <img
      onLoad={() => {
        setLoaded(true);
      }}
      alt={alt}
      className={className}
      src={src}
      height={loaded ? null : unloadedHeight}
      style={{ transition: "opacity 500ms", opacity: loaded ? 1 : 0, ...style }}
    />
  );
}

Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  style: PropTypes.object
};

export default Image;
