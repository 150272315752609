import { Elements } from "@stripe/react-stripe-js";
import Helmet from "react-helmet";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { InternalTextLink } from "components/Links";
import { BasketProvider } from "contexts/BasketContext";
import { ChangeOverTimeContextProvider } from "contexts/ChangeOverTime";
import { LocalStorageContextProvider } from "contexts/LocalStorage";
import { TestBrushConfigContextProvider } from "contexts/TestBrushConfig";
import { OrganisationBasketProvider } from "contexts/organisations/OrganisationBasketContext";
import { OrganisationProvider } from "contexts/organisations/OrganisationContext";
import { ThemeProvider } from "styled-components";

import { AcceptPartnerInvite } from "../components/Acceptances";
import { AcceptPartnerLinkInvite } from "../components/Acceptances";
import { AcceptResultsAccessLink } from "../components/Acceptances";
import ApolloManager from "../components/ApolloManager";
import AppStateProvider from "../components/AppStateProvider";
import AuthProvider from "../components/AuthProvider";
import AuthRoute from "../components/AuthRoute";
import ChangePassword from "../components/ChangePassword";
import ChangePasswordSuccess from "../components/ChangePasswordSuccess";
import CookiePage from "../components/CookiePage";
import DiscountCodeRedirectPage from "../components/DiscountCodeRedirectPage";
import Error404Page from "../components/Error404Page";
import Error500Page from "../components/Error500Page";
import HomePage from "../components/HomePage";
import PartnerShareLinkRedirectPage from "../components/PartnerShareLinkRedirectPage";
import PartnerTermsAndConditionsPage from "../components/PartnerTermsAndConditionsPage";
import PlatformProfileWelcomePage from "../components/PlatformProfileWelcomePage";
import PlatformUserProfileCompletePage from "../components/PlatformUserProfileCompletePage";
import PrivacyPage from "../components/PrivacyPage";
import RegisterKitCompletePage from "../components/RegisterKitCompletePage";
import RegisterKitPage from "../components/RegisterKitPage";
import RegisterKitQuestionsPage from "../components/RegisterKitQuestionsPage";
import RegisterKitSignupPage from "../components/RegisterKitSignupPage";
import RequestPasswordResetDonePage from "../components/RequestPasswordResetDonePage";
import RequestPasswordResetPage from "../components/RequestPasswordResetPage";
import ResetPasswordCompletePage from "../components/ResetPasswordCompletePage";
import ResetPasswordPage from "../components/ResetPasswordPage";
import ShippingAndReturnsPolicyPage from "../components/ShippingAndReturnsPolicy";
import SignupCompletePage from "../components/SignupCompletePage";
import SignupConfirmPage from "../components/SignupConfirmPage";
import SignupDonePage from "../components/SignupDonePage";
import SignupPage from "../components/SignupPage";
import TermsOfServicePage from "../components/TermsOfService";
import TermsOfUsePage from "../components/TermsOfUse";
import ContextProviderManager from "../contexts/ContextProviderManager";
import { getStripePublishableKey } from "../core/config";
import { theme } from "../core/theme";
import {
  ACCEPT_INVITE_SUCCESS_URL,
  ACCEPT_PARTNER_INVITE_PATTERN_URL,
  ACCEPT_PARTNER_LINK_INVITE_PATTERN_URL,
  ACCEPT_RESULTS_ACCESS_REQUEST_PATTERN_URL,
  CATCH_SHOP_URL,
  CHANGE_PASSWORD_COMPLETE_URL,
  CHANGE_PASSWORD_URL,
  COOKIE_URL,
  DASHBOARD_HOME_URL,
  DISCOUNT_CODE_REDIRECT_URL,
  ERROR_404_URL,
  ERROR_500_URL,
  HOME_URL,
  LOGIN_URL,
  OLD_TEST_PATTERN_URL,
  OLD_TESTS_URL,
  ORDERS_URL,
  PARTNER_SHARE_LINK_URL,
  PARTNER_TERMS_URL,
  PARTNERS_URL,
  PLATFORM_PROFILE_COMPLETE_URL,
  PLATFORM_PROFILE_DIET_PREFERENCES_URL,
  PLATFORM_PROFILE_FORM_URL,
  PLATFORM_PROFILE_GOALS_URL,
  PLATFORM_PROFILE_WELCOME_URL,
  PRIVACY_URL,
  QUESTIONNAIRES_URL_BASE,
  REGISTER_KIT_COMPLETE_URL,
  REGISTER_KIT_QUESTIONS_URL,
  REGISTER_KIT_SIGNUP_URL,
  REGISTER_KIT_URL,
  REQUEST_PASSWORD_RESET_DONE,
  REQUEST_PASSWORD_RESET_URL,
  RESET_PASSWORD_COMPLETE_URL,
  RESET_PASSWORD_CONFIRM_PATTERN_URL,
  SETTINGS_URL,
  SIGNUP_COMPLETE_URL,
  SIGNUP_CONFIRM_PATTERN_URL,
  SIGNUP_DONE_URL,
  SIGNUP_URL,
  SHIPPING_AND_RETURNS_URL,
  SUPPLEMENT_PATTERN_URL,
  SUPPLEMENTS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL,
  TEST_FAMILY_PATTERN_URL,
  TEST_PATTERN_URL,
  TESTS_URL,
  THEME_URL,
  RECOMMENDATIONS_URL,
  RECOMMENDATION_URL,
  FOODS_URL,
  TEST_RESULT_URL,
  UPLOAD_TEST,
  TEST_DATAPOINT_URL,
  THEME_SUBSECTOR_URL,
  TEST_RESULTS_URL_BASE,
  EXTERNAL_KIT_REGISTRATION_URL,
  EXTERNAL_KIT_REGISTRATION_COMPLETE_URL
} from "../core/urls";
import ClientManagementPlatform from "./ClientManagementPlatform";
import CrossPageHeader from "./CrossPageHeader";
import Dashboard from "./Dashboard";
import DietPreferences from "./DietPreferences";
import ExternalKitRegistration from "./ExternalKitRegistration";
import ExternalKitRegistrationComplete from "./ExternalKitRegistrationComplete";
import FoodsPage from "./FoodsPage";
import GoalsPage from "./Goals";
import LoggedOutRoute from "./LoggedOutRoute";
import Notifications from "./Notifications";
import PartnerRoute from "./PartnerRoute";
import Partners from "./Partners";
import PlatformUserProfilePage from "./PlatformUserProfilePage";
import QuestionnaireRoutes from "./QuestionnaireRoutes";
import RecommendationPage from "./RecommendationPage";
import RecommendationsPage from "./Recommendations";
import SentryErrorTest from "./SentryErrorTest";
import SettingsPage from "./Settings";
import SignIn from "./SignIn";
import UploadTest from "./UploadTest";
import UserDatapoint from "./UserDatapoint";
import UserResultPage from "./UserResult";
import UserSubsectorPage from "./UserSubsector";
import UserTests from "./UserTests";
import ThemePage from "./UserTheme";
import { ModalProvider } from "./contexts/modal";
import Header from "./header";
import OrderRoutes from "./orders";
import Basket from "./shop/Basket";
import ShopRoutes from "./shop/Routes";
import "./styles.css";

const stripePromise = loadStripe(getStripePublishableKey());

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ApolloManager>
          <Elements stripe={stripePromise}>
            <ModalProvider>
              <ContextProviderManager
                providers={[
                  AppStateProvider,
                  OrganisationProvider,
                  OrganisationBasketProvider,
                  BasketProvider,
                  LocalStorageContextProvider,
                  ChangeOverTimeContextProvider,
                  TestBrushConfigContextProvider
                ]}
              >
                <Helmet>
                  <meta property="og:title" content="Omnos" />
                  <meta
                    property="og:description"
                    content="The Omnos programme has been developed to identify how small adjustments to your lifestyle, nutrition and fitness can have incredible results on your overall wellbeing."
                  />
                  <meta property="og:image" content="/og_1200x630.jpg" />
                  <meta property="og:url" content="https://omnos.me" />
                  <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <Header />
                <CrossPageHeader mt={60} />
                <Basket />
                <Notifications />
                <Switch>
                  {/* Accounts */}
                  <Route path={HOME_URL} component={HomePage} exact />
                  <Route path={SIGNUP_URL} component={SignupPage} exact />
                  <Route path={SIGNUP_DONE_URL} component={SignupDonePage} exact />
                  <Route path={SIGNUP_CONFIRM_PATTERN_URL} component={SignupConfirmPage} exact />
                  <Route path={SIGNUP_COMPLETE_URL} component={SignupCompletePage} exact />
                  <LoggedOutRoute path={LOGIN_URL} exact>
                    <SignIn
                      bottomLinks={
                        <InternalTextLink href={SIGNUP_URL}>Click here to sign up</InternalTextLink>
                      }
                    />
                  </LoggedOutRoute>
                  <Route
                    path={REQUEST_PASSWORD_RESET_URL}
                    component={RequestPasswordResetPage}
                    exact
                  />
                  <Route
                    path={REQUEST_PASSWORD_RESET_DONE}
                    component={RequestPasswordResetDonePage}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_CONFIRM_PATTERN_URL}
                    component={ResetPasswordPage}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_COMPLETE_URL}
                    component={ResetPasswordCompletePage}
                    exact
                  />
                  <AuthRoute path={CHANGE_PASSWORD_URL} component={ChangePassword} exact />
                  <AuthRoute
                    path={CHANGE_PASSWORD_COMPLETE_URL}
                    component={ChangePasswordSuccess}
                    exact
                  />
                  <AuthRoute path={SETTINGS_URL} component={SettingsPage} exact />
                  {/* Invites */}
                  <Route
                    path={ACCEPT_PARTNER_INVITE_PATTERN_URL}
                    component={AcceptPartnerInvite}
                    exact
                  />
                  <Route
                    path={ACCEPT_PARTNER_LINK_INVITE_PATTERN_URL}
                    component={AcceptPartnerLinkInvite}
                    exact
                  />
                  <AuthRoute
                    path={ACCEPT_RESULTS_ACCESS_REQUEST_PATTERN_URL}
                    component={AcceptResultsAccessLink}
                    exact
                  />
                  <Route path={ACCEPT_INVITE_SUCCESS_URL} component={SignupCompletePage} exact />
                  {/* Onboarding */}
                  <AuthRoute
                    path={PLATFORM_PROFILE_WELCOME_URL}
                    component={PlatformProfileWelcomePage}
                    exact
                  />
                  <AuthRoute
                    path={PLATFORM_PROFILE_FORM_URL}
                    component={PlatformUserProfilePage}
                    exact
                  />
                  <AuthRoute path={PLATFORM_PROFILE_GOALS_URL} component={GoalsPage} exact />
                  <AuthRoute
                    path={PLATFORM_PROFILE_DIET_PREFERENCES_URL}
                    component={DietPreferences}
                    exact
                  />
                  <AuthRoute
                    path={PLATFORM_PROFILE_COMPLETE_URL}
                    component={PlatformUserProfileCompletePage}
                    exact
                  />

                  {/* Shop */}
                  <Route path={CATCH_SHOP_URL} component={ShopRoutes} />
                  <Redirect from={SUPPLEMENTS_URL} to="/shop/supplements" exact />
                  <Redirect from={SUPPLEMENT_PATTERN_URL} to="/shop/supplements/:slug" exact />
                  <Redirect from={TESTS_URL} to="/shop/tests" exact />
                  <Redirect from={TEST_PATTERN_URL} to="/shop/tests/:slug" exact />
                  <Redirect from={TEST_FAMILY_PATTERN_URL} to="/shop/tests" exact />
                  <Redirect from={OLD_TESTS_URL} to="/shop/tests" exact />
                  <Redirect from={OLD_TEST_PATTERN_URL} to="/shop/tests/:slug" exact />
                  {/* End of shop */}

                  <Route path={ORDERS_URL} component={OrderRoutes} />

                  {/* Register Kit */}
                  <Route
                    path={EXTERNAL_KIT_REGISTRATION_URL}
                    component={ExternalKitRegistration}
                    exact
                  />
                  <Route
                    path={EXTERNAL_KIT_REGISTRATION_COMPLETE_URL}
                    component={ExternalKitRegistrationComplete}
                    exact
                  />
                  <Route path={REGISTER_KIT_URL} component={RegisterKitPage} exact />
                  <Route path={REGISTER_KIT_SIGNUP_URL} component={RegisterKitSignupPage} exact />
                  <Route
                    path={REGISTER_KIT_QUESTIONS_URL}
                    component={RegisterKitQuestionsPage}
                    exact
                  />
                  <Route
                    path={REGISTER_KIT_COMPLETE_URL}
                    component={RegisterKitCompletePage}
                    exact
                  />
                  {/* Partners */}
                  <Route
                    path={DISCOUNT_CODE_REDIRECT_URL}
                    component={DiscountCodeRedirectPage}
                    exact
                  />
                  <Route
                    path={PARTNER_SHARE_LINK_URL}
                    component={PartnerShareLinkRedirectPage}
                    exact
                  />
                  <Route path={PARTNERS_URL} component={Partners} />
                  <PartnerRoute path="/patients/:patientId" component={ClientManagementPlatform} />
                  {/* Legal */}
                  <Route path={TERMS_OF_SERVICE_URL} exact component={TermsOfServicePage} />
                  <Route path={TERMS_OF_USE_URL} exact component={TermsOfUsePage} />
                  <Route
                    path={SHIPPING_AND_RETURNS_URL}
                    exact
                    component={ShippingAndReturnsPolicyPage}
                  />
                  <Route path={PARTNER_TERMS_URL} exact component={PartnerTermsAndConditionsPage} />
                  <Route path={PRIVACY_URL} exact component={PrivacyPage} />
                  <Route path={COOKIE_URL} exact component={CookiePage} />
                  {/* Errors */}
                  <Route path={ERROR_500_URL} exact component={Error500Page} />
                  <Route path={ERROR_404_URL} exact component={Error404Page} />
                  {/* TPO */}
                  <AuthRoute path={DASHBOARD_HOME_URL} component={Dashboard} exact />
                  <AuthRoute path={THEME_URL} component={ThemePage} exact />
                  <AuthRoute path={THEME_SUBSECTOR_URL} component={UserSubsectorPage} exact />
                  <Route path={QUESTIONNAIRES_URL_BASE} component={QuestionnaireRoutes} />
                  <AuthRoute path={RECOMMENDATIONS_URL} component={RecommendationsPage} exact />
                  <AuthRoute path={RECOMMENDATION_URL} component={RecommendationPage} exact />
                  <AuthRoute path={TEST_RESULTS_URL_BASE} component={UserTests} />
                  <AuthRoute path={TEST_DATAPOINT_URL} component={UserDatapoint} exact />
                  <AuthRoute path={TEST_RESULT_URL} component={UserResultPage} exact />
                  <AuthRoute path={FOODS_URL} component={FoodsPage} exact />
                  <AuthRoute path={UPLOAD_TEST} component={UploadTest} exact />
                  {/* Not found */}
                  <Route path="/sentry-error-test" component={SentryErrorTest} exact />
                  <Error404Page />
                </Switch>
              </ContextProviderManager>
            </ModalProvider>
          </Elements>
        </ApolloManager>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
