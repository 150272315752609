import Box from "components/Box";
import Grid from "components/Grid";
import { ExternalTextLink, InternalTextLink } from "components/Links";
import {
  BLOG_URL,
  CONTACT_US_URL,
  FOR_RETAILERS_URL,
  HELP_CENTER_URL,
  MEET_THE_TEAM_URL,
  OUR_MISSION_URL,
  PARTNERS_AFFILIATE_URL,
  PARTNERS_PRACTITIONER_URL,
  PRIVACY_URL,
  SHIPPING_AND_RETURNS_URL,
  SUPPLEMENTS_URL,
  TERMS_OF_SERVICE_URL,
  TERMS_OF_USE_URL,
  TESTS_URL
} from "core/urls";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";

import { ReactComponent as Logo } from "../images/logo.svg";
import { PanelBoxV2 } from "./Boxes";
import Stack from "./Stack";

const SHOP_TEST_LINK = {
  label: "Tests",
  href: TESTS_URL
};

const SUPPLEMENTS_TEST_LINK = {
  label: "Supplements",
  href: SUPPLEMENTS_URL
};

const OUR_MISSION_LINK = {
  label: "Our Mission",
  href: OUR_MISSION_URL,
  external: true
};

const MEET_THE_TEAM_LINK = {
  label: "Meet the team",
  href: MEET_THE_TEAM_URL,
  external: true
};

const BLOG_LINK = {
  label: "Blog",
  href: BLOG_URL,
  external: true
};

const FOR_PRACTITIONER_LINK = {
  label: "For Practitioners",
  href: "https://www.omnos.me/partners#practitioners",
  external: true
};

const FOR_RETAILERS_LINK = {
  label: "For retailers",
  href: FOR_RETAILERS_URL,
  external: true
};

const TERM_AND_CONDITIONS_LINK = {
  label: "Terms & Conditions",
  href: TERMS_OF_SERVICE_URL
};

const TERMS_OF_USE_LINK = {
  label: "Terms of Use",
  href: TERMS_OF_USE_URL
};

const SHIPPING_POLICY_LINK = {
  label: "Shipping Policy",
  href: SHIPPING_AND_RETURNS_URL
};

const PRIVATE_POLICY_LINK = {
  label: "Privacy Policy",
  href: PRIVACY_URL
};

const HELP_CENTER_LINK = {
  label: "Help centre",
  href: HELP_CENTER_URL,
  external: true
};

const CONTACT_US_LINK = {
  label: "Contact us",
  href: CONTACT_US_URL,
  external: true
};

const FAQS_LINK = {
  label: "FAQ's",
  href: HELP_CENTER_URL,
  external: true
};

const partnerLinks = [
  {
    column: "Shop",
    links: [SHOP_TEST_LINK, SUPPLEMENTS_TEST_LINK]
  },
  {
    column: "About Us",
    links: [OUR_MISSION_LINK, MEET_THE_TEAM_LINK, BLOG_LINK]
  },
  {
    column: "Policies",
    links: [TERM_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK, SHIPPING_POLICY_LINK, PRIVATE_POLICY_LINK]
  },
  {
    column: "Help and support",
    links: [HELP_CENTER_LINK, CONTACT_US_LINK, FAQS_LINK]
  }
];

const userLinks = [
  {
    column: "Shop",
    links: [SHOP_TEST_LINK, SUPPLEMENTS_TEST_LINK]
  },
  {
    column: "About Us",
    links: [OUR_MISSION_LINK, MEET_THE_TEAM_LINK, BLOG_LINK]
  },
  {
    column: "Partners",
    links: [FOR_PRACTITIONER_LINK, FOR_RETAILERS_LINK]
  },
  {
    column: "Policies",
    links: [TERM_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK, SHIPPING_POLICY_LINK, PRIVATE_POLICY_LINK]
  },
  {
    column: "Help and support",
    links: [HELP_CENTER_LINK, CONTACT_US_LINK, FAQS_LINK]
  }
];

function FooterTemplate({ links }) {
  return (
    <Grid
      gridTemplateColumns={[
        "repeat( auto-fit, minmax(120px, 1fr) )",
        "repeat( auto-fit, minmax(120px, 1fr) )",
        "260px repeat( auto-fit, minmax(120px, 1fr) )"
      ]}
      gridRowGap={[50, 80, 80]}
      gridColumnGap={[50, 80, 80]}
    >
      <Box gridColumnStart={["1", "unset"]} gridColumnEnd={["3", "unset"]}>
        <Logo height={50} />
      </Box>
      {links.map((col, idx) => (
        <Stack key={idx} gap={40}>
          <Box color="white" fontFamily="gilroyBold" fontSize={16}>
            {col.column}
          </Box>
          <Stack gap={20}>
            {col.links.map(link =>
              link.external ? (
                <ExternalTextLink
                  color="white"
                  key={link.label}
                  href={link.href}
                  fontFamily="gilroyMedium"
                  fontSize={16}
                >
                  {link.label}
                </ExternalTextLink>
              ) : (
                <InternalTextLink
                  color="white"
                  key={link.label}
                  href={link.href}
                  fontFamily="gilroyMedium"
                  fontSize={16}
                >
                  {link.label}
                </InternalTextLink>
              )
            )}
          </Stack>
        </Stack>
      ))}
    </Grid>
  );
}

export default function Footer() {
  const isPartnerPage = usePartnerPageCheck();

  return (
    <PanelBoxV2
      maxWidth={1538}
      outer={{
        py: 40,
        bg: isPartnerPage ? "dark" : "purple",
        px: [20, 20, 95],
        "data-component-name": "Footer"
      }}
    >
      <FooterTemplate links={isPartnerPage ? partnerLinks : userLinks} />
    </PanelBoxV2>
  );
}
