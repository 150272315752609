export default function RegenerusLogo({ color }) {
  return (
    <svg
      width="255"
      height="68"
      viewBox="0 0 255 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_68_4932)">
        <path
          d="M3.85012 7.58586C4.97816 6.85269 6.41746 6.48792 8.1644 6.48792V8.12667H7.66605C5.89571 8.12667 4.42404 8.65658 3.25101 9.71279C2.07798 10.7708 1.49147 12.4967 1.49147 14.8922V25.8081H0V6.87083H1.49327V10.7763C1.93585 9.38068 2.72207 8.31722 3.85012 7.58586Z"
          fill={color}
        />
        <path
          d="M29.0415 16.9482H13.4755C13.5205 18.6451 13.8731 20.0751 14.537 21.2384C15.2009 22.4017 16.0753 23.2728 17.1583 23.8535C18.2414 24.4342 19.427 24.7246 20.7098 24.7246C22.4801 24.7246 23.9626 24.2654 25.1572 23.3472C26.3518 22.4289 27.1039 21.1803 27.4133 19.5978H28.9732C28.6187 21.5287 27.712 23.0913 26.2511 24.289C24.7902 25.4868 22.9425 26.0857 20.708 26.0857C19.0258 26.0857 17.5218 25.6901 16.194 24.9006C14.8663 24.1112 13.8264 22.9769 13.0743 21.4997C12.3223 20.0243 11.9463 18.2966 11.9463 16.3203C11.9463 14.344 12.3169 12.6236 13.0581 11.1591C13.7994 9.69453 14.8339 8.573 16.1616 7.79445C17.4894 7.01591 19.0042 6.62573 20.708 6.62573C22.4118 6.62573 23.932 7.01591 25.2058 7.79445C26.4778 8.573 27.4457 9.60742 28.1096 10.8977C28.7735 12.188 29.1045 13.5782 29.1045 15.0645C29.1045 15.9011 29.0829 16.529 29.0379 16.9482H29.0415ZM26.7674 11.4022C26.1359 10.2408 25.2796 9.37332 24.1947 8.80347C23.1098 8.23363 21.9386 7.94871 20.6756 7.94871C19.4126 7.94871 18.2468 8.23363 17.1745 8.80347C16.1005 9.37332 15.2279 10.239 14.5532 11.4022C13.8785 12.5655 13.5187 13.9829 13.4737 15.6561H27.613C27.6796 13.9829 27.3971 12.5637 26.7674 11.4022Z"
          fill={color}
        />
        <path
          d="M47.4951 8.07395C48.8228 9.03941 49.7512 10.2771 50.2837 11.7888V6.87074H51.777V26.4015C51.777 28.1455 51.4118 29.6735 50.6813 30.9874C49.9509 32.3013 48.9668 33.3122 47.7272 34.0217C46.4876 34.7313 45.1059 35.0852 43.5784 35.0852C41.3223 35.0852 39.4512 34.5154 37.9688 33.3757C36.4863 32.236 35.513 30.7025 35.0488 28.7716H36.5421C36.9846 30.2833 37.814 31.4865 39.032 32.3812C40.2482 33.2759 41.7649 33.7241 43.5784 33.7241C44.8396 33.7241 45.9784 33.4283 46.9967 32.8349C48.015 32.2414 48.8157 31.3921 49.404 30.2887C49.9905 29.1835 50.2837 27.8878 50.2837 26.3996V20.8537C49.753 22.3654 48.8174 23.614 47.4789 24.603C46.1404 25.5921 44.5301 26.0857 42.6501 26.0857C40.9679 26.0857 39.4692 25.6846 38.1523 24.8825C36.8353 24.0804 35.8062 22.9407 35.065 21.4652C34.3237 19.9898 33.9531 18.2749 33.9531 16.3221C33.9531 14.3694 34.3237 12.6599 35.065 11.1954C35.8062 9.73085 36.8353 8.60386 38.1523 7.81262C39.4692 7.02319 40.9679 6.62756 42.6501 6.62756C44.5535 6.62756 46.1691 7.1103 47.4951 8.07576V8.07395ZM49.3032 11.9449C48.6501 10.6782 47.7596 9.70181 46.6315 9.01582C45.5035 8.32983 44.2423 7.98684 42.848 7.98684C41.4537 7.98684 40.1043 8.31894 38.9979 8.98134C37.8914 9.64374 37.0278 10.6038 36.4089 11.8578C35.79 13.1136 35.4788 14.6017 35.4788 16.3221C35.4788 18.0426 35.7882 19.5307 36.4089 20.7865C37.0278 22.0423 37.8968 23.0078 39.014 23.6811C40.1313 24.3562 41.4087 24.6919 42.848 24.6919C44.2873 24.6919 45.5089 24.3489 46.6477 23.663C47.7866 22.977 48.6771 22.0006 49.3194 20.7339C49.9617 19.4672 50.2819 17.9972 50.2819 16.3221C50.2819 14.6471 49.9545 13.2134 49.3032 11.9449Z"
          fill={color}
        />
        <path
          d="M74.6455 16.9482H59.0795C59.1245 18.6451 59.4771 20.0751 60.141 21.2384C60.8049 22.4017 61.6793 23.2728 62.7623 23.8535C63.8454 24.4342 65.031 24.7246 66.3138 24.7246C68.0841 24.7246 69.5666 24.2654 70.7612 23.3472C71.9558 22.4289 72.7079 21.1803 73.0173 19.5978H74.5772C74.2227 21.5287 73.316 23.0913 71.8551 24.289C70.3942 25.4868 68.5465 26.0857 66.312 26.0857C64.6298 26.0857 63.1258 25.6901 61.798 24.9006C60.4703 24.1112 59.4304 22.9769 58.6783 21.4997C57.9263 20.0243 57.5503 18.2966 57.5503 16.3203C57.5503 14.344 57.9209 12.6236 58.6621 11.1591C59.4034 9.69453 60.4379 8.573 61.7656 7.79445C63.0934 7.01591 64.6082 6.62573 66.312 6.62573C68.0158 6.62573 69.536 7.01591 70.8098 7.79445C72.0818 8.573 73.0497 9.60742 73.7136 10.8977C74.3775 12.188 74.7085 13.5782 74.7085 15.0645C74.7085 15.9011 74.6869 16.529 74.6419 16.9482H74.6455ZM72.3714 11.4022C71.74 10.2408 70.8836 9.37332 69.7987 8.80347C68.7138 8.23363 67.5426 7.94871 66.2796 7.94871C65.0166 7.94871 63.8508 8.23363 62.7785 8.80347C61.7045 9.37332 60.8319 10.239 60.1572 11.4022C59.4825 12.5655 59.1227 13.9829 59.0777 15.6561H73.217C73.2836 13.9829 73.0011 12.5637 72.3714 11.4022Z"
          fill={color}
        />
        <path
          d="M93.8616 8.5622C95.211 9.94506 95.8857 11.9976 95.8857 14.7179V25.8081H94.4248V14.8232C94.4248 12.5692 93.8814 10.8416 92.7984 9.64381C91.7135 8.44605 90.2418 7.84717 88.3833 7.84717C86.5248 7.84717 84.8822 8.49868 83.72 9.79988C82.5578 11.1029 81.9767 13.032 81.9767 15.589V25.8081H80.4834V6.87082H81.9767V10.8815C82.4858 9.46415 83.3314 8.37709 84.5152 7.62032C85.699 6.86537 87.0646 6.48608 88.6136 6.48608C90.76 6.48608 92.5087 7.17752 93.858 8.56038L93.8616 8.5622Z"
          fill={color}
        />
        <path
          d="M118.589 16.9482H103.023C103.068 18.6451 103.421 20.0751 104.084 21.2384C104.748 22.4017 105.623 23.2728 106.706 23.8535C107.789 24.4342 108.974 24.7246 110.257 24.7246C112.028 24.7246 113.51 24.2654 114.705 23.3472C115.899 22.4289 116.651 21.1803 116.961 19.5978H118.521C118.166 21.5287 117.259 23.0913 115.798 24.289C114.338 25.4868 112.49 26.0857 110.255 26.0857C108.573 26.0857 107.069 25.6901 105.741 24.9006C104.414 24.1112 103.374 22.9769 102.622 21.4997C101.87 20.0243 101.494 18.2966 101.494 16.3203C101.494 14.344 101.864 12.6236 102.606 11.1591C103.347 9.69453 104.381 8.573 105.709 7.79445C107.037 7.01591 108.552 6.62573 110.255 6.62573C111.959 6.62573 113.479 7.01591 114.753 7.79445C116.025 8.573 116.993 9.60742 117.657 10.8977C118.321 12.188 118.652 13.5782 118.652 15.0645C118.652 15.9011 118.63 16.529 118.585 16.9482H118.589ZM116.315 11.4022C115.683 10.2408 114.827 9.37332 113.742 8.80347C112.657 8.23363 111.486 7.94871 110.223 7.94871C108.96 7.94871 107.794 8.23363 106.722 8.80347C105.648 9.37332 104.775 10.239 104.101 11.4022C103.426 12.5655 103.066 13.9829 103.021 15.6561H117.16C117.227 13.9829 116.944 12.5637 116.315 11.4022Z"
          fill={color}
        />
        <path
          d="M128.281 7.58586C129.409 6.85269 130.848 6.48792 132.595 6.48792V8.12667H132.097C130.326 8.12667 128.855 8.65658 127.682 9.71279C126.508 10.7708 125.922 12.4967 125.922 14.8922V25.8081H124.429V6.87083H125.922V10.7763C126.365 9.38068 127.151 8.31722 128.279 7.58586H128.281Z"
          fill={color}
        />
        <path
          d="M152.544 6.87073V25.808H151.05V21.6921C150.541 23.1348 149.694 24.2328 148.512 24.9877C147.328 25.7445 145.973 26.122 144.446 26.122C142.299 26.122 140.545 25.4305 139.185 24.0477C137.825 22.6648 137.143 20.6123 137.143 17.8919V6.87073H138.604V17.7867C138.604 20.0424 139.145 21.7683 140.23 22.9661C141.313 24.1638 142.787 24.7627 144.645 24.7627C146.504 24.7627 148.146 24.113 149.309 22.81C150.471 21.5088 151.052 19.5779 151.052 17.0208V6.87254H152.545L152.544 6.87073Z"
          fill={color}
        />
        <path
          d="M160.325 24.6919C159.053 23.7628 158.339 22.438 158.184 20.7157H159.712C159.821 21.948 160.359 22.9243 161.322 23.6448C162.284 24.3653 163.596 24.7264 165.255 24.7264C166.626 24.7264 167.716 24.3598 168.524 23.6285C169.331 22.8953 169.734 21.9952 169.734 20.9263C169.734 20.1822 169.519 19.5724 169.087 19.0951C168.655 18.6197 168.119 18.2531 167.477 17.9972C166.834 17.7413 165.962 17.4618 164.855 17.1606C163.527 16.7885 162.453 16.422 161.635 16.0626C160.816 15.7033 160.118 15.1625 159.544 14.4402C158.968 13.7197 158.681 12.7543 158.681 11.5456C158.681 10.6618 158.934 9.84336 159.443 9.0866C159.953 8.33165 160.66 7.73277 161.568 7.28996C162.475 6.84897 163.504 6.62756 164.656 6.62756C166.536 6.62756 168.058 7.12118 169.22 8.11024C170.382 9.0993 171.006 10.464 171.095 12.208H169.601C169.535 10.9522 169.071 9.93047 168.207 9.13923C167.343 8.34979 166.149 7.95417 164.623 7.95417C163.34 7.95417 162.277 8.30261 161.437 9.0013C160.595 9.69818 160.176 10.5475 160.176 11.5474C160.176 12.4312 160.413 13.1463 160.89 13.6925C161.365 14.2388 161.951 14.658 162.649 14.9484C163.346 15.2387 164.271 15.5472 165.42 15.8721C166.681 16.2205 167.694 16.5635 168.457 16.9011C169.22 17.2386 169.868 17.7268 170.398 18.3656C170.929 19.0062 171.195 19.8592 171.195 20.9281C171.195 21.9044 170.94 22.7882 170.432 23.5777C169.923 24.3689 169.214 24.9841 168.308 25.4269C167.401 25.8679 166.383 26.0893 165.255 26.0893C163.241 26.0893 161.599 25.6247 160.325 24.6938V24.6919Z"
          fill={color}
        />
        <path d="M189.118 0V25.8081H187.625V0H189.118Z" fill={color} />
        <path
          d="M196.039 11.1954C196.78 9.73085 197.809 8.60386 199.126 7.81262C200.443 7.02319 201.942 6.62756 203.624 6.62756C205.528 6.62756 207.141 7.11574 208.469 8.0921C209.797 9.06845 210.725 10.3134 211.258 11.8233V6.87074H212.751V25.808H211.258V20.821C210.727 22.3563 209.792 23.6176 208.453 24.6048C207.114 25.5939 205.504 26.0875 203.624 26.0875C201.942 26.0875 200.443 25.6864 199.126 24.8843C197.809 24.0822 196.78 22.9425 196.039 21.4671C195.298 19.9916 194.927 18.2767 194.927 16.324C194.927 14.3713 195.298 12.6617 196.039 11.1972V11.1954ZM210.277 11.9449C209.624 10.6782 208.734 9.70181 207.606 9.01582C206.478 8.32983 205.216 7.98684 203.822 7.98684C202.428 7.98684 201.078 8.31894 199.972 8.98134C198.866 9.64374 198.002 10.6038 197.383 11.8578C196.762 13.1136 196.453 14.6017 196.453 16.3221C196.453 18.0426 196.762 19.5307 197.383 20.7865C198.002 22.0424 198.871 23.0078 199.988 23.6811C201.105 24.3562 202.383 24.6919 203.822 24.6919C205.261 24.6919 206.483 24.3489 207.622 23.663C208.761 22.977 209.651 22.0006 210.294 20.7339C210.936 19.4672 211.256 17.9972 211.256 16.3221C211.256 14.6471 210.929 13.2134 210.277 11.9449Z"
          fill={color}
        />
        <path
          d="M223.769 8.09031C225.097 7.11396 226.7 6.62578 228.582 6.62578C230.264 6.62578 231.762 7.0214 233.079 7.81084C234.396 8.60208 235.425 9.72906 236.167 11.1936C236.908 12.6581 237.278 14.3677 237.278 16.3204C237.278 18.2731 236.908 19.988 236.167 21.4635C235.425 22.9407 234.396 24.0786 233.079 24.8807C231.762 25.6828 230.264 26.0839 228.582 26.0839C226.7 26.0839 225.091 25.5957 223.753 24.6194C222.414 23.643 221.479 22.3872 220.948 20.8537V25.8062H219.455V0H220.948V11.8578C221.5 10.3225 222.441 9.06848 223.769 8.09213V8.09031ZM234.821 11.856C234.202 10.6002 233.333 9.64195 232.216 8.97956C231.098 8.31716 229.809 7.98505 228.349 7.98505C226.89 7.98505 225.694 8.32805 224.566 9.01404C223.438 9.70003 222.553 10.6764 221.91 11.9431C221.268 13.2098 220.948 14.6689 220.948 16.3204C220.948 17.9718 221.268 19.4654 221.91 20.7321C222.553 21.9988 223.438 22.977 224.566 23.6612C225.694 24.3472 226.955 24.6902 228.349 24.6902C229.744 24.6902 231.098 24.3526 232.216 23.6793C233.333 23.006 234.202 22.0406 234.821 20.7847C235.44 19.5289 235.751 18.0408 235.751 16.3204C235.751 14.5999 235.442 13.1118 234.821 11.856Z"
          fill={color}
        />
        <path
          d="M244.132 24.6919C242.86 23.7628 242.145 22.438 241.991 20.7157H243.518C243.628 21.948 244.166 22.9243 245.128 23.6448C246.091 24.3653 247.402 24.7264 249.061 24.7264C250.432 24.7264 251.522 24.3598 252.33 23.6285C253.138 22.8953 253.541 21.9952 253.541 20.9263C253.541 20.1822 253.325 19.5724 252.893 19.0951C252.462 18.6197 251.925 18.2531 251.283 17.9972C250.641 17.7413 249.768 17.4618 248.662 17.1606C247.334 16.7885 246.26 16.422 245.441 16.0626C244.623 15.7033 243.925 15.1625 243.351 14.4402C242.775 13.7197 242.487 12.7543 242.487 11.5456C242.487 10.6618 242.741 9.84336 243.25 9.0866C243.759 8.33165 244.466 7.73277 245.375 7.28996C246.282 6.84897 247.311 6.62756 248.462 6.62756C250.342 6.62756 251.864 7.12118 253.027 8.11024C254.189 9.0993 254.813 10.464 254.901 12.208H253.408C253.341 10.9522 252.877 9.93047 252.014 9.13923C251.15 8.34979 249.955 7.95417 248.43 7.95417C247.147 7.95417 246.084 8.30261 245.244 9.0013C244.402 9.69818 243.982 10.5475 243.982 11.5474C243.982 12.4312 244.22 13.1463 244.697 13.6925C245.172 14.2388 245.758 14.658 246.456 14.9484C247.152 15.2387 248.077 15.5472 249.227 15.8721C250.488 16.2205 251.501 16.5635 252.264 16.9011C253.027 17.2386 253.674 17.7268 254.205 18.3656C254.736 19.0062 255.002 19.8592 255.002 20.9281C255.002 21.9044 254.746 22.7882 254.239 23.5777C253.73 24.3689 253.021 24.9841 252.114 25.4269C251.208 25.8679 250.189 26.0893 249.061 26.0893C247.048 26.0893 245.405 25.6247 244.132 24.6938V24.6919Z"
          fill={color}
        />
        <path
          d="M140.866 54.5615C142.175 54.5615 143.237 53.4906 143.237 52.1696C143.237 50.8486 142.175 49.7777 140.866 49.7777C139.556 49.7777 138.495 50.8486 138.495 52.1696C138.495 53.4906 139.556 54.5615 140.866 54.5615Z"
          fill={color}
        />
        <g opacity="0.7">
          <path
            d="M126.178 47.718C127.487 47.718 128.549 46.6471 128.549 45.3261C128.549 44.0051 127.487 42.9342 126.178 42.9342C124.868 42.9342 123.807 44.0051 123.807 45.3261C123.807 46.6471 124.868 47.718 126.178 47.718Z"
            fill={color}
          />
          <path
            d="M126.179 40.9543C127.489 40.9543 128.551 39.8834 128.551 38.5624C128.551 37.2414 127.489 36.1705 126.179 36.1705C124.87 36.1705 123.808 37.2414 123.808 38.5624C123.808 39.8834 124.87 40.9543 126.179 40.9543Z"
            fill={color}
          />
          <path
            d="M127.8 40.3046V40.3082L127.381 40.8563C127.192 41.1031 127.079 41.3771 127.054 41.6584C127.038 41.8454 127.036 42.0341 127.052 42.221C127.075 42.5078 127.191 42.7854 127.383 43.034L127.8 43.5748V43.5785L126.183 45.3352L124.558 43.5785V43.5748L124.965 43.054C125.161 42.8036 125.278 42.5223 125.301 42.2337C125.319 42.0359 125.318 41.8363 125.298 41.6385C125.271 41.3572 125.158 41.0831 124.967 40.8363L124.557 40.3064V40.3028L126.181 38.566L127.799 40.3028L127.8 40.3046Z"
            fill={color}
          />
          <path
            d="M126.178 54.4744C127.487 54.4744 128.549 53.4036 128.549 52.0826C128.549 50.7616 127.487 49.6907 126.178 49.6907C124.868 49.6907 123.807 50.7616 123.807 52.0826C123.807 53.4036 124.868 54.4744 126.178 54.4744Z"
            fill={color}
          />
          <path
            d="M127.8 47.0611V47.0647L127.381 47.6128C127.192 47.8596 127.079 48.1336 127.054 48.4149C127.038 48.6018 127.036 48.7906 127.052 48.9775C127.075 49.2642 127.191 49.5419 127.383 49.7905L127.8 50.3313V50.3349L126.183 52.0917L124.558 50.3349V50.3313L124.965 49.8105C125.161 49.56 125.278 49.2787 125.301 48.9902C125.319 48.7924 125.318 48.5928 125.298 48.3949C125.271 48.1137 125.158 47.8396 124.967 47.5928L124.557 47.0629V47.0593L126.181 45.3225L127.799 47.0593L127.8 47.0611Z"
            fill={color}
          />
          <path
            d="M126.178 61.24C127.487 61.24 128.549 60.1691 128.549 58.8481C128.549 57.5271 127.487 56.4562 126.178 56.4562C124.868 56.4562 123.807 57.5271 123.807 58.8481C123.807 60.1691 124.868 61.24 126.178 61.24Z"
            fill={color}
          />
          <path
            d="M127.8 53.8266V53.8302L127.381 54.3783C127.192 54.6251 127.079 54.8991 127.054 55.1804C127.038 55.3673 127.036 55.5561 127.052 55.743C127.075 56.0297 127.191 56.3074 127.383 56.556L127.8 57.0968V57.1005L126.183 58.8572L124.558 57.1005V57.0968L124.965 56.576C125.161 56.3255 125.278 56.0442 125.301 55.7557C125.319 55.5579 125.318 55.3583 125.298 55.1604C125.271 54.8792 125.158 54.6051 124.967 54.3583L124.557 53.8284V53.8248L126.181 52.088L127.799 53.8248L127.8 53.8266Z"
            fill={color}
          />
          <path
            d="M126.178 68.0001C127.487 68.0001 128.549 66.9292 128.549 65.6082C128.549 64.2872 127.487 63.2163 126.178 63.2163C124.868 63.2163 123.807 64.2872 123.807 65.6082C123.807 66.9292 124.868 68.0001 126.178 68.0001Z"
            fill={color}
          />
          <path
            d="M127.8 60.5867V60.5903L127.381 61.1384C127.192 61.3852 127.079 61.6592 127.054 61.9405C127.038 62.1275 127.036 62.3162 127.052 62.5031C127.075 62.7899 127.191 63.0675 127.383 63.3162L127.8 63.857V63.8606L126.183 65.6173L124.558 63.8606V63.857L124.965 63.3361C125.161 63.0857 125.278 62.8044 125.301 62.5158C125.319 62.318 125.318 62.1184 125.298 61.9206C125.271 61.6393 125.158 61.3653 124.967 61.1184L124.557 60.5885V60.5849L126.181 58.8481L127.799 60.5849L127.8 60.5867Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M133.522 47.7452C134.831 47.7452 135.893 46.6743 135.893 45.3533C135.893 44.0323 134.831 42.9614 133.522 42.9614C132.212 42.9614 131.15 44.0323 131.15 45.3533C131.15 46.6743 132.212 47.7452 133.522 47.7452Z"
            fill={color}
          />
          <path
            d="M133.522 54.5017C134.831 54.5017 135.893 53.4308 135.893 52.1098C135.893 50.7888 134.831 49.7179 133.522 49.7179C132.212 49.7179 131.15 50.7888 131.15 52.1098C131.15 53.4308 132.212 54.5017 133.522 54.5017Z"
            fill={color}
          />
          <path
            d="M135.144 47.0883V47.0919L134.725 47.64C134.536 47.8868 134.423 48.1608 134.398 48.4421C134.381 48.629 134.38 48.8178 134.396 49.0047C134.419 49.2914 134.534 49.5691 134.727 49.8177L135.144 50.3585V50.3622L133.527 52.1189L131.902 50.3622V50.3585L132.309 49.8377C132.505 49.5873 132.622 49.306 132.645 49.0174C132.663 48.8196 132.661 48.62 132.642 48.4222C132.615 48.1409 132.501 47.8668 132.311 47.62L131.9 47.0901V47.0865L133.525 45.3497L135.142 47.0865L135.144 47.0883Z"
            fill={color}
          />
          <path
            d="M133.522 61.2672C134.831 61.2672 135.893 60.1963 135.893 58.8753C135.893 57.5543 134.831 56.4834 133.522 56.4834C132.212 56.4834 131.15 57.5543 131.15 58.8753C131.15 60.1963 132.212 61.2672 133.522 61.2672Z"
            fill={color}
          />
          <path
            d="M135.144 53.852V53.8556L134.725 54.4037C134.536 54.6505 134.423 54.9245 134.398 55.2058C134.381 55.3927 134.38 55.5815 134.396 55.7684C134.419 56.0551 134.534 56.3328 134.727 56.5814L135.144 57.1222V57.1258L133.527 58.8825L131.902 57.1258V57.1222L132.309 56.6014C132.505 56.3509 132.622 56.0696 132.645 55.7811C132.663 55.5833 132.661 55.3836 132.642 55.1858C132.615 54.9045 132.501 54.6305 132.311 54.3837L131.9 53.8538V53.8502L133.525 52.1134L135.142 53.8502L135.144 53.852Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M118.852 47.7452C120.161 47.7452 121.223 46.6743 121.223 45.3533C121.223 44.0323 120.161 42.9614 118.852 42.9614C117.542 42.9614 116.48 44.0323 116.48 45.3533C116.48 46.6743 117.542 47.7452 118.852 47.7452Z"
            fill={color}
          />
          <path
            d="M118.852 54.5017C120.161 54.5017 121.223 53.4308 121.223 52.1098C121.223 50.7888 120.161 49.7179 118.852 49.7179C117.542 49.7179 116.48 50.7888 116.48 52.1098C116.48 53.4308 117.542 54.5017 118.852 54.5017Z"
            fill={color}
          />
          <path
            d="M120.473 47.0883V47.0919L120.054 47.64C119.865 47.8868 119.751 48.1608 119.726 48.4421C119.71 48.629 119.708 48.8178 119.724 49.0047C119.748 49.2914 119.863 49.5691 120.055 49.8177L120.473 50.3585V50.3622L118.855 52.1189L117.231 50.3622V50.3585L117.637 49.8377C117.834 49.5873 117.95 49.306 117.974 49.0174C117.992 48.8196 117.99 48.62 117.97 48.4222C117.943 48.1409 117.83 47.8668 117.639 47.62L117.229 47.0901V47.0865L118.854 45.3497L120.471 47.0865L120.473 47.0883Z"
            fill={color}
          />
          <path
            d="M118.852 61.2672C120.161 61.2672 121.223 60.1963 121.223 58.8753C121.223 57.5543 120.161 56.4834 118.852 56.4834C117.542 56.4834 116.48 57.5543 116.48 58.8753C116.48 60.1963 117.542 61.2672 118.852 61.2672Z"
            fill={color}
          />
          <path
            d="M120.473 53.852V53.8556L120.054 54.4037C119.865 54.6505 119.751 54.9245 119.726 55.2058C119.71 55.3927 119.708 55.5815 119.724 55.7684C119.748 56.0551 119.863 56.3328 120.055 56.5814L120.473 57.1222V57.1258L118.855 58.8825L117.231 57.1258V57.1222L117.637 56.6014C117.834 56.3509 117.95 56.0696 117.974 55.7811C117.992 55.5833 117.99 55.3836 117.97 55.1858C117.943 54.9045 117.83 54.6305 117.639 54.3837L117.229 53.8538V53.8502L118.854 52.1134L120.471 53.8502L120.473 53.852Z"
            fill={color}
          />
        </g>
        <path
          d="M111.524 54.5615C112.833 54.5615 113.895 53.4906 113.895 52.1696C113.895 50.8486 112.833 49.7777 111.524 49.7777C110.214 49.7777 109.152 50.8486 109.152 52.1696C109.152 53.4906 110.214 54.5615 111.524 54.5615Z"
          fill={color}
        />
        <g opacity="0.7">
          <path
            d="M96.8302 47.7088C98.1398 47.7088 99.2015 46.6379 99.2015 45.3169C99.2015 43.9959 98.1398 42.925 96.8302 42.925C95.5206 42.925 94.459 43.9959 94.459 45.3169C94.459 46.6379 95.5206 47.7088 96.8302 47.7088Z"
            fill={color}
          />
          <path
            d="M96.8322 40.9451C98.1418 40.9451 99.2034 39.8743 99.2034 38.5533C99.2034 37.2323 98.1418 36.1614 96.8322 36.1614C95.5226 36.1614 94.4609 37.2323 94.4609 38.5533C94.4609 39.8743 95.5226 40.9451 96.8322 40.9451Z"
            fill={color}
          />
          <path
            d="M98.4513 40.2954V40.2991L98.0321 40.8471C97.8432 41.094 97.7299 41.368 97.7047 41.6493C97.6885 41.8362 97.6867 42.0249 97.7029 42.2119C97.7263 42.4986 97.8414 42.7763 98.0339 43.0249L98.4513 43.5657V43.5693L96.8339 45.326L95.2093 43.5693V43.5657L95.6159 43.0448C95.812 42.7944 95.929 42.5131 95.9524 42.2246C95.9704 42.0268 95.9685 41.8271 95.9488 41.6293C95.9218 41.348 95.8084 41.074 95.6177 40.8272L95.2075 40.2973V40.2936L96.8321 38.5569L98.4495 40.2936L98.4513 40.2954Z"
            fill={color}
          />
          <path
            d="M96.8302 54.4653C98.1398 54.4653 99.2015 53.3944 99.2015 52.0734C99.2015 50.7524 98.1398 49.6815 96.8302 49.6815C95.5206 49.6815 94.459 50.7524 94.459 52.0734C94.459 53.3944 95.5206 54.4653 96.8302 54.4653Z"
            fill={color}
          />
          <path
            d="M98.4513 47.0519V47.0555L98.0321 47.6036C97.8432 47.8504 97.7299 48.1245 97.7047 48.4057C97.6885 48.5927 97.6867 48.7814 97.7029 48.9683C97.7263 49.2551 97.8414 49.5327 98.0339 49.7814L98.4513 50.3222V50.3258L96.8339 52.0825L95.2093 50.3258V50.3222L95.6159 49.8013C95.812 49.5509 95.929 49.2696 95.9524 48.981C95.9704 48.7832 95.9685 48.5836 95.9488 48.3858C95.9218 48.1045 95.8084 47.8305 95.6177 47.5837L95.2075 47.0537V47.0501L96.8321 45.3134L98.4495 47.0501L98.4513 47.0519Z"
            fill={color}
          />
          <path
            d="M96.8302 61.2308C98.1398 61.2308 99.2015 60.1599 99.2015 58.8389C99.2015 57.5179 98.1398 56.447 96.8302 56.447C95.5206 56.447 94.459 57.5179 94.459 58.8389C94.459 60.1599 95.5206 61.2308 96.8302 61.2308Z"
            fill={color}
          />
          <path
            d="M98.4513 53.8156V53.8192L98.0321 54.3673C97.8432 54.6141 97.7299 54.8881 97.7047 55.1694C97.6885 55.3563 97.6867 55.5451 97.7029 55.732C97.7263 56.0187 97.8414 56.2964 98.0339 56.545L98.4513 57.0858V57.0895L96.8339 58.8462L95.2093 57.0895V57.0858L95.6159 56.565C95.812 56.3146 95.929 56.0333 95.9524 55.7447C95.9704 55.5469 95.9685 55.3473 95.9488 55.1495C95.9218 54.8682 95.8084 54.5941 95.6177 54.3473L95.2075 53.8174V53.8138L96.8321 52.077L98.4495 53.8138L98.4513 53.8156Z"
            fill={color}
          />
          <path
            d="M96.8302 67.9909C98.1398 67.9909 99.2015 66.92 99.2015 65.599C99.2015 64.278 98.1398 63.2072 96.8302 63.2072C95.5206 63.2072 94.459 64.278 94.459 65.599C94.459 66.92 95.5206 67.9909 96.8302 67.9909Z"
            fill={color}
          />
          <path
            d="M98.4513 60.5776V60.5812L98.0321 61.1292C97.8432 61.3761 97.7299 61.6501 97.7047 61.9314C97.6885 62.1183 97.6867 62.307 97.7029 62.494C97.7263 62.7807 97.8414 63.0584 98.0339 63.307L98.4513 63.8478V63.8514L96.8339 65.6081L95.2093 63.8514V63.8478L95.6159 63.327C95.812 63.0765 95.929 62.7952 95.9524 62.5067C95.9704 62.3089 95.9685 62.1092 95.9488 61.9114C95.9218 61.6301 95.8084 61.3561 95.6177 61.1093L95.2075 60.5794V60.5757L96.8321 58.839L98.4495 60.5757L98.4513 60.5776Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M104.174 47.7362C105.484 47.7362 106.546 46.6653 106.546 45.3443C106.546 44.0233 105.484 42.9524 104.174 42.9524C102.865 42.9524 101.803 44.0233 101.803 45.3443C101.803 46.6653 102.865 47.7362 104.174 47.7362Z"
            fill={color}
          />
          <path
            d="M104.174 54.4926C105.484 54.4926 106.546 53.4218 106.546 52.1007C106.546 50.7797 105.484 49.7089 104.174 49.7089C102.865 49.7089 101.803 50.7797 101.803 52.1007C101.803 53.4218 102.865 54.4926 104.174 54.4926Z"
            fill={color}
          />
          <path
            d="M105.796 47.0774V47.0811L105.376 47.6291C105.187 47.8759 105.074 48.15 105.049 48.4313C105.033 48.6182 105.031 48.8069 105.047 48.9938C105.071 49.2806 105.186 49.5582 105.378 49.8069L105.796 50.3477V50.3513L104.178 52.108L102.554 50.3513V50.3477L102.96 49.8268C103.156 49.5764 103.273 49.2951 103.297 49.0065C103.315 48.8087 103.313 48.6091 103.293 48.4113C103.266 48.13 103.153 47.856 102.962 47.6092L102.552 47.0792V47.0756L104.176 45.3389L105.794 47.0756L105.796 47.0774Z"
            fill={color}
          />
          <path
            d="M104.174 61.2563C105.484 61.2563 106.546 60.1854 106.546 58.8644C106.546 57.5434 105.484 56.4725 104.174 56.4725C102.865 56.4725 101.803 57.5434 101.803 58.8644C101.803 60.1854 102.865 61.2563 104.174 61.2563Z"
            fill={color}
          />
          <path
            d="M105.796 53.8429V53.8466L105.376 54.3946C105.187 54.6414 105.074 54.9155 105.049 55.1968C105.033 55.3837 105.031 55.5724 105.047 55.7593C105.071 56.0461 105.186 56.3237 105.378 56.5724L105.796 57.1132V57.1168L104.178 58.8735L102.554 57.1168V57.1132L102.96 56.5923C103.156 56.3419 103.273 56.0606 103.297 55.7721C103.315 55.5742 103.313 55.3746 103.293 55.1768C103.266 54.8955 103.153 54.6215 102.962 54.3747L102.552 53.8448V53.8411L104.176 52.1044L105.794 53.8411L105.796 53.8429Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M89.5045 47.7362C90.8141 47.7362 91.8758 46.6653 91.8758 45.3443C91.8758 44.0233 90.8141 42.9524 89.5045 42.9524C88.1949 42.9524 87.1333 44.0233 87.1333 45.3443C87.1333 46.6653 88.1949 47.7362 89.5045 47.7362Z"
            fill={color}
          />
          <path
            d="M89.5045 54.4926C90.8141 54.4926 91.8758 53.4218 91.8758 52.1007C91.8758 50.7797 90.8141 49.7089 89.5045 49.7089C88.1949 49.7089 87.1333 50.7797 87.1333 52.1007C87.1333 53.4218 88.1949 54.4926 89.5045 54.4926Z"
            fill={color}
          />
          <path
            d="M91.1256 47.0774V47.0811L90.7065 47.6291C90.5175 47.8759 90.4042 48.15 90.379 48.4313C90.3628 48.6182 90.361 48.8069 90.3772 48.9938C90.4006 49.2806 90.5157 49.5582 90.7083 49.8069L91.1256 50.3477V50.3513L89.5082 52.108L87.8836 50.3513V50.3477L88.2902 49.8268C88.4863 49.5764 88.6033 49.2951 88.6267 49.0065C88.6447 48.8087 88.6429 48.6091 88.6231 48.4113C88.5961 48.13 88.4827 47.856 88.292 47.6092L87.8818 47.0792V47.0756L89.5064 45.3389L91.1238 47.0756L91.1256 47.0774Z"
            fill={color}
          />
          <path
            d="M89.5045 61.2563C90.8141 61.2563 91.8758 60.1854 91.8758 58.8644C91.8758 57.5434 90.8141 56.4725 89.5045 56.4725C88.1949 56.4725 87.1333 57.5434 87.1333 58.8644C87.1333 60.1854 88.1949 61.2563 89.5045 61.2563Z"
            fill={color}
          />
          <path
            d="M91.1256 53.8429V53.8466L90.7065 54.3946C90.5175 54.6414 90.4042 54.9155 90.379 55.1968C90.3628 55.3837 90.361 55.5724 90.3772 55.7593C90.4006 56.0461 90.5157 56.3237 90.7083 56.5724L91.1256 57.1132V57.1168L89.5082 58.8735L87.8836 57.1168V57.1132L88.2902 56.5923C88.4863 56.3419 88.6033 56.0606 88.6267 55.7721C88.6447 55.5742 88.6429 55.3746 88.6231 55.1768C88.5961 54.8955 88.4827 54.6215 88.292 54.3747L87.8818 53.8448V53.8411L89.5064 52.1044L91.1238 53.8411L91.1256 53.8429Z"
            fill={color}
          />
        </g>
        <path
          d="M82.1764 54.5525C83.486 54.5525 84.5477 53.4816 84.5477 52.1606C84.5477 50.8396 83.486 49.7687 82.1764 49.7687C80.8668 49.7687 79.8052 50.8396 79.8052 52.1606C79.8052 53.4816 80.8668 54.5525 82.1764 54.5525Z"
          fill={color}
        />
        <path
          d="M170.208 54.5615C171.517 54.5615 172.579 53.4906 172.579 52.1696C172.579 50.8486 171.517 49.7777 170.208 49.7777C168.898 49.7777 167.836 50.8486 167.836 52.1696C167.836 53.4906 168.898 54.5615 170.208 54.5615Z"
          fill={color}
        />
        <g opacity="0.7">
          <path
            d="M155.52 47.718C156.829 47.718 157.891 46.6471 157.891 45.3261C157.891 44.0051 156.829 42.9342 155.52 42.9342C154.21 42.9342 153.148 44.0051 153.148 45.3261C153.148 46.6471 154.21 47.718 155.52 47.718Z"
            fill={color}
          />
          <path
            d="M155.521 40.9543C156.831 40.9543 157.892 39.8834 157.892 38.5624C157.892 37.2414 156.831 36.1705 155.521 36.1705C154.212 36.1705 153.15 37.2414 153.15 38.5624C153.15 39.8834 154.212 40.9543 155.521 40.9543Z"
            fill={color}
          />
          <path
            d="M157.141 40.3046V40.3082L156.722 40.8563C156.533 41.1031 156.419 41.3771 156.394 41.6584C156.378 41.8454 156.376 42.0341 156.392 42.221C156.416 42.5078 156.531 42.7854 156.723 43.034L157.141 43.5748V43.5785L155.523 45.3352L153.899 43.5785V43.5748L154.305 43.054C154.501 42.8036 154.618 42.5223 154.642 42.2337C154.66 42.0359 154.658 41.8363 154.638 41.6385C154.611 41.3572 154.498 41.0831 154.307 40.8363L153.897 40.3064V40.3028L155.522 38.566L157.139 40.3028L157.141 40.3046Z"
            fill={color}
          />
          <path
            d="M155.52 54.4744C156.829 54.4744 157.891 53.4036 157.891 52.0826C157.891 50.7616 156.829 49.6907 155.52 49.6907C154.21 49.6907 153.148 50.7616 153.148 52.0826C153.148 53.4036 154.21 54.4744 155.52 54.4744Z"
            fill={color}
          />
          <path
            d="M157.141 47.0611V47.0647L156.722 47.6128C156.533 47.8596 156.419 48.1336 156.394 48.4149C156.378 48.6018 156.376 48.7906 156.392 48.9775C156.416 49.2642 156.531 49.5419 156.723 49.7905L157.141 50.3313V50.3349L155.523 52.0917L153.899 50.3349V50.3313L154.305 49.8105C154.501 49.56 154.618 49.2787 154.642 48.9902C154.66 48.7924 154.658 48.5928 154.638 48.3949C154.611 48.1137 154.498 47.8396 154.307 47.5928L153.897 47.0629V47.0593L155.522 45.3225L157.139 47.0593L157.141 47.0611Z"
            fill={color}
          />
          <path
            d="M155.52 61.24C156.829 61.24 157.891 60.1691 157.891 58.8481C157.891 57.5271 156.829 56.4562 155.52 56.4562C154.21 56.4562 153.148 57.5271 153.148 58.8481C153.148 60.1691 154.21 61.24 155.52 61.24Z"
            fill={color}
          />
          <path
            d="M157.141 53.8266V53.8302L156.722 54.3783C156.533 54.6251 156.419 54.8991 156.394 55.1804C156.378 55.3673 156.376 55.5561 156.392 55.743C156.416 56.0297 156.531 56.3074 156.723 56.556L157.141 57.0968V57.1005L155.523 58.8572L153.899 57.1005V57.0968L154.305 56.576C154.501 56.3255 154.618 56.0442 154.642 55.7557C154.66 55.5579 154.658 55.3583 154.638 55.1604C154.611 54.8792 154.498 54.6051 154.307 54.3583L153.897 53.8284V53.8248L155.522 52.088L157.139 53.8248L157.141 53.8266Z"
            fill={color}
          />
          <path
            d="M155.52 68.0001C156.829 68.0001 157.891 66.9292 157.891 65.6082C157.891 64.2872 156.829 63.2163 155.52 63.2163C154.21 63.2163 153.148 64.2872 153.148 65.6082C153.148 66.9292 154.21 68.0001 155.52 68.0001Z"
            fill={color}
          />
          <path
            d="M157.141 60.5867V60.5903L156.722 61.1384C156.533 61.3852 156.419 61.6592 156.394 61.9405C156.378 62.1275 156.376 62.3162 156.392 62.5031C156.416 62.7899 156.531 63.0675 156.723 63.3162L157.141 63.857V63.8606L155.523 65.6173L153.899 63.8606V63.857L154.305 63.3361C154.501 63.0857 154.618 62.8044 154.642 62.5158C154.66 62.318 154.658 62.1184 154.638 61.9206C154.611 61.6393 154.498 61.3653 154.307 61.1184L153.897 60.5885V60.5849L155.522 58.8481L157.139 60.5849L157.141 60.5867Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M162.863 47.7452C164.173 47.7452 165.235 46.6743 165.235 45.3533C165.235 44.0323 164.173 42.9614 162.863 42.9614C161.554 42.9614 160.492 44.0323 160.492 45.3533C160.492 46.6743 161.554 47.7452 162.863 47.7452Z"
            fill={color}
          />
          <path
            d="M162.863 54.5017C164.173 54.5017 165.235 53.4308 165.235 52.1098C165.235 50.7888 164.173 49.7179 162.863 49.7179C161.554 49.7179 160.492 50.7888 160.492 52.1098C160.492 53.4308 161.554 54.5017 162.863 54.5017Z"
            fill={color}
          />
          <path
            d="M164.485 47.0883V47.0919L164.065 47.64C163.876 47.8868 163.763 48.1608 163.738 48.4421C163.722 48.629 163.72 48.8178 163.736 49.0047C163.76 49.2914 163.875 49.5691 164.067 49.8177L164.485 50.3585V50.3622L162.867 52.1189L161.243 50.3622V50.3585L161.649 49.8377C161.845 49.5873 161.962 49.306 161.986 49.0174C162.004 48.8196 162.002 48.62 161.982 48.4222C161.955 48.1409 161.842 47.8668 161.651 47.62L161.241 47.0901V47.0865L162.865 45.3497L164.483 47.0865L164.485 47.0883Z"
            fill={color}
          />
          <path
            d="M162.863 61.2672C164.173 61.2672 165.235 60.1963 165.235 58.8753C165.235 57.5543 164.173 56.4834 162.863 56.4834C161.554 56.4834 160.492 57.5543 160.492 58.8753C160.492 60.1963 161.554 61.2672 162.863 61.2672Z"
            fill={color}
          />
          <path
            d="M164.485 53.852V53.8556L164.065 54.4037C163.876 54.6505 163.763 54.9245 163.738 55.2058C163.722 55.3927 163.72 55.5815 163.736 55.7684C163.76 56.0551 163.875 56.3328 164.067 56.5814L164.485 57.1222V57.1258L162.867 58.8825L161.243 57.1258V57.1222L161.649 56.6014C161.845 56.3509 161.962 56.0696 161.986 55.7811C162.004 55.5833 162.002 55.3836 161.982 55.1858C161.955 54.9045 161.842 54.6305 161.651 54.3837L161.241 53.8538V53.8502L162.865 52.1134L164.483 53.8502L164.485 53.852Z"
            fill={color}
          />
        </g>
        <g opacity="0.8">
          <path
            d="M148.194 47.7452C149.503 47.7452 150.565 46.6743 150.565 45.3533C150.565 44.0323 149.503 42.9614 148.194 42.9614C146.884 42.9614 145.822 44.0323 145.822 45.3533C145.822 46.6743 146.884 47.7452 148.194 47.7452Z"
            fill={color}
          />
          <path
            d="M148.194 54.5017C149.503 54.5017 150.565 53.4308 150.565 52.1098C150.565 50.7888 149.503 49.7179 148.194 49.7179C146.884 49.7179 145.822 50.7888 145.822 52.1098C145.822 53.4308 146.884 54.5017 148.194 54.5017Z"
            fill={color}
          />
          <path
            d="M149.815 47.0883V47.0919L149.395 47.64C149.207 47.8868 149.093 48.1608 149.068 48.4421C149.052 48.629 149.05 48.8178 149.066 49.0047C149.09 49.2914 149.205 49.5691 149.397 49.8177L149.815 50.3585V50.3622L148.197 52.1189L146.573 50.3622V50.3585L146.979 49.8377C147.175 49.5873 147.292 49.306 147.316 49.0174C147.334 48.8196 147.332 48.62 147.312 48.4222C147.285 48.1409 147.172 47.8668 146.981 47.62L146.571 47.0901V47.0865L148.195 45.3497L149.813 47.0865L149.815 47.0883Z"
            fill={color}
          />
          <path
            d="M148.194 61.2672C149.503 61.2672 150.565 60.1963 150.565 58.8753C150.565 57.5543 149.503 56.4834 148.194 56.4834C146.884 56.4834 145.822 57.5543 145.822 58.8753C145.822 60.1963 146.884 61.2672 148.194 61.2672Z"
            fill={color}
          />
          <path
            d="M149.815 53.852V53.8556L149.395 54.4037C149.207 54.6505 149.093 54.9245 149.068 55.2058C149.052 55.3927 149.05 55.5815 149.066 55.7684C149.09 56.0551 149.205 56.3328 149.397 56.5814L149.815 57.1222V57.1258L148.197 58.8825L146.573 57.1258V57.1222L146.979 56.6014C147.175 56.3509 147.292 56.0696 147.316 55.7811C147.334 55.5833 147.332 55.3836 147.312 55.1858C147.285 54.9045 147.172 54.6305 146.981 54.3837L146.571 53.8538V53.8502L148.195 52.1134L149.813 53.8502L149.815 53.852Z"
            fill={color}
          />
        </g>
        <path
          d="M170.227 54.5852C171.537 54.5852 172.598 53.5143 172.598 52.1933C172.598 50.8723 171.537 49.8014 170.227 49.8014C168.918 49.8014 167.856 50.8723 167.856 52.1933C167.856 53.5143 168.918 54.5852 170.227 54.5852Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_68_4932">
          <rect width="255" height="68" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
