import gql from "graphql-tag";
import { PAGE_INFO_FIELDS } from "graphql/pagination";
import { ORDER_FIELDS, ORDER_TEST_ITEM_FIELDS } from "graphql/shop";

import { ORGANISATION_BASKET_FIELDS, ORGANISATION_FIELDS } from "./shared";

export const ORGANISATION_LIST_QUERY = gql`
  query OrganisationListQuery {
    organisations {
      ...OrganisationFields
    }
  }
  ${ORGANISATION_FIELDS}
`;

export const ORGANISATION_BASKET_DETAIL_QUERY = gql`
  query OrganisationBasketQuery($organisation: Int!) {
    basket(organisation: $organisation) {
      ...OrganisationBasketFields
    }
  }
  ${ORGANISATION_BASKET_FIELDS}
`;

export const ORGANISATION_ORDERS_LIST_QUERY = gql`
  query OrganisationOrdersListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
    $filter: String
  ) {
    organisationOrdersPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
      filter: $filter
    ) {
      items {
        id
        checkoutDate
        user {
          id
          firstName
          lastName
          email
        }
        sourceBasket {
          id
          payee
          paymentStatus
          orderStatus
          basketTotalPrice
          currency {
            id
            isoCode
          }
          paymentInvoice {
            id
            stripeId
            paymentStatus
            url
            invoiceUrl
          }
          paymentCheckoutSession {
            id
            stripeId
            paymentStatus
            url
            invoiceUrl
          }
        }
        status
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_ORDER_DETAIL_QUERY = gql`
  query OrganisationOrderDetailQuery($organisation: Int!, $id: String!) {
    organisationOrder(organisation: $organisation, id: $id) {
      id
      checkoutDate
      user {
        id
        firstName
        lastName
        email
      }
      status
      sourceBasket {
        id
        organisation {
          id
          name
        }
        shippingAddress {
          id
          name
          line1
          line2
          city
          postcode
          country {
            id
            name
          }
        }
        practitioner {
          id
          user {
            firstName
            lastName
            email
          }
        }
        currency {
          id
          isoCode
        }
        paymentInvoice {
          id
          stripeId
          paymentStatus
          url
          invoiceUrl
        }
        paymentCheckoutSession {
          id
          stripeId
          paymentStatus
          url
          invoiceUrl
        }
        payee
        basketRrpTotalPrice
        basketTotalPrice
        basketTotalCommission
        basketShippingCost
        paymentStatus
        orderStatus
        productLineItems {
          id
          product {
            id
            name
          }
          productOption {
            id
            testProductOptionFamily {
              id
              name
            }
          }
          clinicLocation {
            id
            address
            clinic {
              name
            }
          }
          quantity
          pricePerUnit
          fullPricePerUnit
          rrpPerUnit
          clinicFeesPerUnit
        }
      }
      ...OrderFields
      testItems {
        ...OrderTestItemFields
      }
    }
  }
  ${ORDER_FIELDS}
  ${ORDER_TEST_ITEM_FIELDS}
`;

export const ORGANISATION_COMMISSIONS_LIST_QUERY = gql`
  query OrganisationCommissionsListQuery(
    $organisation: Int!
    $page: Int
    $orderBy: String
    $search: String
  ) {
    organisationCommissionsPagination(
      organisation: $organisation
      page: $page
      orderBy: $orderBy
      search: $search
    ) {
      items {
        id
        checkoutDate
        user {
          id
          firstName
          lastName
          email
        }
        sourceBasket {
          id
          payee
          paymentStatus
          currency {
            id
            isoCode
          }
          basketTotalCommission
        }
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;

export const ORGANISATION_ACCOUNT_BALANCE_QUERY = gql`
  query OrganisationAccountBalanceQuery($organisation: Int!) {
    connectedAccountBalances(organisation: $organisation) {
      id
      currency
      pending
      available
    }
  }
`;

export const CONNECTED_USERS_QUERY = gql`
  query ConnectedUsersQuery($page: Int, $orderBy: String, $search: String) {
    connectedUsersPagination(page: $page, orderBy: $orderBy, search: $search) {
      items {
        id
        platformUserProfile {
          id
          user {
            id
            firstName
            lastName
            pageAvailability {
              testResultsAvailable
            }
          }
        }
        email
        createdDateFormatted
        granted
        status
      }
      pageInfo {
        ...PageInfoFields
      }
    }
  }
  ${PAGE_INFO_FIELDS}
`;
