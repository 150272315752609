import { useContext, useRef } from "react";

import { FormProvider } from "react-hook-form";

import { gql } from "@apollo/client";
import Box from "components/Box";
import { InternalTextLink } from "components/Links";
import PartnerContext from "contexts/PartnerContext";
import useDjangoGraphqlForm from "hooks/form/useDjangoGraphqlForm";
import Asterisk from "tpo/Asterisk";
import { PanelBoxV2 } from "tpo/Boxes";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import ControlledFormField from "tpo/ControlledFormField";
import EditFormButtons from "tpo/EditFormButtons";
import FloatingLabelInput from "tpo/FloatingLabelInput";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import { PROFILE_FORM_FIELDS } from "tpo/partnerDashboard/common/graphql/types";
import ButtonV2 from "v2/Buttons";

const EDIT_YOUR_DETAILS_MUTATION = gql`
  mutation EditYourDetails($input: PartnerYourDetailsMutationInput!) {
    partnerYourDetailsMutation(input: $input) {
      partnerUserProfile {
        ...ProfileFormFields
      }
      errors {
        field
        messages
      }
    }
  }
  ${PROFILE_FORM_FIELDS}
`;

function initializeYourDetails(partnerUserProfile) {
  return {
    id: partnerUserProfile.pk,
    firstName: partnerUserProfile.user.firstName || "",
    lastName: partnerUserProfile.user.lastName || "",
    jobRole: partnerUserProfile.jobRole || "",
    phoneNumber: partnerUserProfile.phoneNumber || ""
  };
}

export default function YourDetails() {
  const partnerUserProfile = useContext(PartnerContext);

  const submitButtonRef = useRef();

  const { methods, id, onSubmit, nonFieldError } = useDjangoGraphqlForm({
    mutation: EDIT_YOUR_DETAILS_MUTATION,
    mutationName: "partnerYourDetailsMutation",
    defaultValues: initializeYourDetails(partnerUserProfile),
    handleSuccess: ({ data, api: { reset } }) => {
      reset(initializeYourDetails(data.partnerYourDetailsMutation.partnerUserProfile));
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(true);
        submitButtonRef.current.setPending(false);
      }
    },
    handleFailure: () => {
      if (submitButtonRef.current) {
        submitButtonRef.current.setSuccessful(false);
        submitButtonRef.current.setPending(false);
      }
    }
  });

  if (!id) {
    // this can't happen unless the field is accidentally
    // removed from the query
    // if id was missing however it would try to create a new instance
    // server side instead of editing the existing for this ID
    return null;
  }

  return (
    <FormProvider {...methods} submitButtonRef={submitButtonRef}>
      <PanelBoxV2
        maxWidth={1532}
        outer={{
          pt: [30, 30, 60],
          pb: [50, 50, 80],
          px: [20],
          bg: "haze"
        }}
      >
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <PanelBoxV2 maxWidth={1020}>
            <PanelBoxV2
              maxWidth={760}
              outer={{
                pt: [30, 30, 60],
                pb: [50, 50, 80],
                bg: "white",
                borderRadius: 5,
                px: 20
              }}
            >
              <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]} lineHeight="130%">
                Your details
                <Asterisk />
              </Box>
              {!nonFieldError && <Spacer py={[20]} />}
              {nonFieldError && (
                <Box color="red" py={20}>
                  {nonFieldError}
                </Box>
              )}
              <Stack gap={20}>
                <ControlledFormField
                  name="firstName"
                  label="First name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField
                  name="lastName"
                  label="Last name"
                  Component={FloatingLabelInput}
                />
                <ControlledFormField name="jobRole" label="Role" Component={FloatingLabelInput} />
                <ControlledFormField
                  name="phoneNumber"
                  label="Contact Number"
                  Component={FloatingLabelInput}
                />
              </Stack>
              <Spacer py={[20]} />
              <Stack gap={20} flexDirection={["column", "row", "row"]} alignItems="flex-start">
                <ButtonV2
                  as={InternalTextLink}
                  underline={false}
                  color="dark"
                  rightIcon={<ChevronComponent />}
                  href="/settings"
                >
                  edit personal profile
                </ButtonV2>
              </Stack>
            </PanelBoxV2>
          </PanelBoxV2>
          <Spacer py={20} />
          <Center gap={20}>
            <EditFormButtons />
          </Center>
        </form>
      </PanelBoxV2>
    </FormProvider>
  );
}
