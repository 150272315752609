import Box from "components/Box";
import Grid from "components/Grid";
import { ExternalTextLink } from "components/Links";
import { getClientManagementUserTestUrl } from "core/urls";
import styled from "styled-components";
import ChevronComponent from "tpo/Chevron";
import Spacer from "tpo/Spacer";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

const NewResultsAvailableStack = styled(Stack)`
  .item:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.haze}};
  }
`;

export default function NewResultsAvailable({ userTests }) {
  return (
    <Box
      mx="auto"
      bg="white"
      borderRadius={5}
      maxWidth={1280}
      px={20}
      width="100%"
      pt={[30, 30, 60]}
      pb={[50, 50, 80]}
    >
      <Box maxWidth={1020} mx="auto">
        <Box fontFamily="gilroyBold" fontSize={[24, 24, 36]}>
          New Results Available
        </Box>
        <Spacer py={2} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          Below are a list of new results from your clients that require you to review and release.
        </Box>
        <Spacer py={30} />
        <NewResultsAvailableStack>
          {userTests.map(userTest => (
            <Grid
              key={userTest.id}
              className="item"
              gridTemplateColumns={["1fr", "1fr", "auto auto 1fr"]}
              pb={20}
            >
              <Box>
                <Box fontFamily="gilroyBold" fontSize={16}>
                  {userTest.user.fullName}
                </Box>
                <Box fontFamily="gilroyMedium" fontSize={14}>
                  {userTest.user.email}
                </Box>
              </Box>
              <Box>
                <Box fontFamily="gilroyBold" fontSize={16}>
                  {userTest.testProduct.name}
                </Box>
                {userTest.orderTestItem?.providerTestId && (
                  <Box fontFamily="gilroyMedium" fontSize={14}>
                    Kit Registration: {userTest.orderTestItem.providerTestId}
                  </Box>
                )}
              </Box>
              <ButtonV2
                color="green"
                rightIcon={<ChevronComponent />}
                sx={{
                  ml: [0, 0, "auto"],
                  mr: ["auto", "auto", 0]
                }}
                as={ExternalTextLink}
                underline={false}
                href={getClientManagementUserTestUrl(userTest.user.id, userTest.id)}
              >
                review
              </ButtonV2>
            </Grid>
          ))}
        </NewResultsAvailableStack>
      </Box>
    </Box>
  );
}
