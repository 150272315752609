import React, { useEffect } from "react";

import { useHistory, useLocation, Route } from "react-router-dom";

import { gql } from "@apollo/client";
import Loading from "components/Loading";
import { LOGIN_URL, PARTNERS_URL } from "core/urls";
import { PARTNER_USER_PROFILE_FIELDS } from "graphql/accounts";
import { useDataLoader } from "hooks/useDataLoader";

const CHECK_PARTNER_AND_AUTH_STATUS = gql`
  query CheckPartnerAndAuthStatus {
    partnerUserProfile {
      ...PartnerUserProfileFields
    }
    user {
      id
    }
  }
  ${PARTNER_USER_PROFILE_FIELDS}
`;

function PartnerRoute(props) {
  const history = useHistory();
  const location = useLocation();

  // If there is a partner then the user
  // must be logged in also since it goes on the
  // user found on the request

  const { data, loading } = useDataLoader({
    query: CHECK_PARTNER_AND_AUTH_STATUS,
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data) {
      if (data.user) {
        // user logged in
        if (!data.partnerUserProfile || !data.partnerUserProfile.approved) {
          // logged in but not partner so redirect to /partners
          history.push(PARTNERS_URL);
        }
      } else {
        // user not logged in
        history.push(`${LOGIN_URL}?next=${location.pathname}`);
      }
    }
  }, [data, history, location]);

  if (loading || !data) {
    return <Loading />;
  }

  return <Route {...props} />;
}

export default PartnerRoute;
