import styled from "styled-components";
import { grid, layout, space, typography } from "styled-system";

const Grid = styled.div.attrs(props => ({
  "data-component-name": props["data-component-name"]
}))`
  ${grid}
  ${layout}
  ${space}
  ${typography}
  ${props => {
    return `align-items: ${props.alignItems}`;
  }}
`;

Grid.defaultProps = {
  display: "grid",
  gridRowGap: 20,
  gridColumnGap: 20
};

export default Grid;
